import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../frame/icon";

export default function InfoGrid({ redirectTo, img, title, description }) {
  return (
    <div className="col mt-3">
      <div className="story__item style2 story--theme-color">
        <div className="story__inner">
          <Link to={redirectTo}>
            <div className="story__thumb position-relative">
              <img src={img} alt={`event`} style={{ maxWidth: "100%" }} />
            </div>
          </Link>
          <div className="story__content p-0">
            <Link to={redirectTo}>
              <h4>{title}</h4>
              <p>{description}</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export function InfoGrid2({ img, title, description, redirectTo }) {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex container py-3"
      onClick={() => {
        navigate(redirectTo);
      }}
    >
      <div className="w-auto">
        <Icon
          style={{ width: "90px", border: "0" }}
          borderRadius={"0"}
          img={img}
        />
      </div>
      <div className="ms-3 w-100">
        <p className="fs-4">{title}</p>
        <div>{description}</div>
      </div>
    </div>
  );
}
