import React from "react";
import { themeColor } from "../generalConst/generalConst";

export default function Header({ style, title }) {
  return (
    <header className="header position-sticky" id="navbar">
      <div className="header__bottom" style={style}>
        <div className="container">
          <nav className="navbar navbar-expand-lg justify-content-center">
            <div className="fw-bold fs-3 ">{title}</div>
          </nav>
        </div>
      </div>
    </header>
  );
}
