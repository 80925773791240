import React, { useState, useEffect } from "react";
import { Icon } from "../library/component/frame/icon";
import socket from "../library/config/socket";
import { Buffer } from "buffer";
import RoundedTheme from "../library/component/layout/roundedTheme";
import { themeColor } from "../library/component/generalConst/generalConst";
import HorizontalMenu from "../library/component/menu/horizontalMenu";
import { RiTeamFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { useNavigate, useOutletContext } from "react-router-dom";
import ColorButton from "../library/component/button/colorButton";
import { MdEdit } from "react-icons/md";

export default function Profile() {
  const auth = useOutletContext();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    img: "",
    iCode: "",
  });
  useEffect(() => {
    socket.emit("profile").once("profileRes", (data) => {
      if (data.status) {
        setInfo(data.message);
      }
    });
  }, []);

  const menu =
    auth.role === "matchMaker"
      ? [
          {
            icon: <FaUserEdit size={36} color={"#FFF"} />,

            link: "/matchMakerEdit",
          },
          {
            icon: <RiTeamFill size={36} color={"#FFF"} />,
            link: "/member",
          },
        ]
      : auth.role === "user"
      ? [
          {
            icon: <FaUserEdit size={36} color={"#FFF"} />,
            link: "/userEdit",
          },
        ]
      : [
          {
            icon: <FaUserEdit size={24} color={"#FFF"} />,
            link: "/adminEdit",
          },
          {
            icon: <RiTeamFill size={24} color={"#FFF"} />,
            link: "/member",
          },
        ];

  const handleLogout = () => {
    socket.emit("logout");
    window.location.reload();
  };

  return (
    <>
      <RoundedTheme backgroundColor={themeColor("blueGreen").hex} height="50%">
        <div className="mb-3 text-center position-relative" onClick={()=>{navigate("/userImage")}}>
          <Icon
            img={
              info.img
                ? "data:image/jpg;base64," +
                  new Buffer.from(info.img).toString("base64")
                : ""
            }
            style={{ width: "30%", aspectRatio: 1 / 1 }}
          />
          <MdEdit
            style={{ position: "absolute", bottom: 0, right: 10 }}
            size={24}
          />
        </div>
        {auth.role === "matchMaker" && (
          <div className="fs-4 text-center">邀請碼：{info.iCode}</div>
        )}
        <div className=" mt-5 text-center">
          <HorizontalMenu menu={menu} />
        </div>
      </RoundedTheme>
      <div
        className="position-absolute translate-middle"
        style={{ bottom: "20%", left: "50%" }}
      >
        <ColorButton
          label={"登出"}
          color={"#FFF"}
          className="default-btn p-4"
          backgroundColor={themeColor("blueGreen").hex}
          onClick={handleLogout}
        />
      </div>
    </>
  );
}
