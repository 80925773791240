import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import socket from "../library/config/socket";
import ColorButton from "../library/component/button/colorButton";
import { genderColor } from "../library/themeSetting/setting";
import { themeColor } from "../library/component/generalConst/generalConst";
import { MobileTitleHeader } from "../library/component/header/mobileReturnHeader";
import Sidebar, { SidebarWithMenuButton } from "../library/component/sidebar/sidebar";
import { AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import CircularLoading from "../library/component/loading/circularLoading";
export default function LoginPage() {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [sidebarActive , setSidebarActive] = useState(false)
  const [loading,setLoading] = useState(false)
  const handleSubmit = (e) => {
    setLoading(true)
    setPhoneErr(false);
    setPasswordErr(false);
    e.preventDefault();
    socket
      .emit("login", {
        phone: phone,
        password: password,
      })
      .once("loginRes", (data) => {
        if (data.status === true) {
          window.location.reload();
        } else {
          if (data.message === "no user") {
            setPhoneErr(true);
          } else if (data.message === "wrong password") {
            setPasswordErr(true);
          }
        }
        setLoading(false)
      });
  };
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      if (data.authentication) {
        navigate("/profile");
      } else if (!data) {
        window.location.reload();
      }
    });
    socket.once("registerRes", () => {
      navigate("/register");
    });
  }, []);
  const handleJoinMember = () => {
    socket.emit("register", { role: "user" });
  };

  const handleApplyMatchMaker = () => {
    socket.emit("register", { role: "matchMaker" });
  };

  return (
    <> 
    <div className="main-content w-75 absolute-middle">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
      
        <FormControl fullWidth>
          <TextField
            id="phone"
            label="用戶名稱"
            error={phoneErr}
            helperText={phoneErr && "沒有這個用戶"}
            variant="outlined"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            value={phone}
          />
          <TextField
            id="password"
            type={"password"}
            label="密碼"
            className="mt-3"
            error={passwordErr}
            helperText={passwordErr && "密碼錯誤"}
            variant="outlined"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
        </FormControl>

        <div className="text-center my-3 ">
          <ColorButton
            color={"#fff"}
            className="default-btn p-3"
            backgroundColor={genderColor[0]}
            label="登入"
            style={{borderRadius:"12%"}}
            onClick={handleSubmit}
          />
        </div>
      </form>

      <div className="d-flex justify-content-between">
        <ColorButton
          color={"#fff"}
          className="default-btn p-3"
          backgroundColor={genderColor[1]}
          label="註冊會員"
          onClick={handleJoinMember}
          style={{borderRadius:"12%"}}
        />
        <ColorButton
          color={"#fff"}
          className="default-btn p-3"
          backgroundColor={themeColor("blueGreen", 1).hex}
          label="註冊婚戀顧問"
          style={{borderRadius:"12%"}}
          onClick={handleApplyMatchMaker}
        />
      </div>
    </div>
    {loading && <CircularLoading />}
    </> 
  );
}
