import React from "react";
import SideMenu from "../../library/component/menu/sideMenu";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { HiUser, HiUserAdd } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import { Outlet, useOutletContext } from "react-router-dom";
import Header from "../../library/component/header/header";
import {
  bottomTabMap,
  themeColor,
} from "../../library/component/generalConst/generalConst";
import MaxHeight from "../../library/component/maxHeight/maxHeight";
import { MobileTitleHeader } from "../../library/component/header/mobileReturnHeader";
import BottomTab from "../../library/component/bottomTab/bottomTab";

export default function TeamSection() {
  const auth = useOutletContext();
  return (
    <>
      <MobileTitleHeader returnButton={"/profile"} title={"團隊管理"} />
      <MaxHeight height={window.screen.availHeight - 60}>
        <div className="row h-100">
          <SideMenu
            className={"col-auto"}
            backgroundColor="#cbddcb"
            list={[
              { icon: <HiUser size={20} />, link: "/member" },
              { icon: <HiUserAdd size={20} />, link: "/memberApplication" },
              { icon: <RiTeamFill size={20} />, link: "/team" },
              {
                icon: <AiOutlineUsergroupAdd size={20} />,
                link: "/matchMakerApplication",
              },
            ]}
          />
          <div className="col">
            <Outlet />
          </div>
        </div>
      </MaxHeight>
    </>
  );
}
