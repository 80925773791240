import React, { useState } from "react";
import {
  Autocomplete,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { auth } from "../../firebase-config";
import Checkbox from "@mui/material/Checkbox";
import {
  blood,
  education,
  hobby,
  language,
  religion,
  themeColor,
} from "../../library/component/generalConst/generalConst";
import Slider from "@mui/material/Slider";
import { useNavigate, useOutletContext } from "react-router-dom";
import socket from "../../library/config/socket";
import CircularLoading from "../../library/component/loading/circularLoading";
import ColorButton from "../../library/component/button/colorButton";

const profileTitle = "資料詳情";

export default function RegisterUserInfo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    nickname: "",
    birthday: "",
    gender: "",
    salary: "1",
    education: "",
    hobby: [],
    asset: "1",
    religion: "",
    language: [],
    occupation: "",
    blood: "",
    weight: "",
    height: "",
    location: [],
    intro: "",
    tag: [],
  });

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      let state = values[e.target.name];
      state.indexOf(e.target.value) === -1
        ? state.push(e.target.value)
        : state.splice(state.indexOf(e.target.value), 1);
      value = state;
    } else if (e.target.type === "number") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    socket.emit("registerUser", values).once("registerUserRes", (data) => {
      if (data.status) {
        setLoading(false);
        navigate("/registerUserImage");
      } else {
        setLoading(false);
        alert(data.message);
      }
    });
  };

  return (
    <>
      <div className="container">
        <FormControl fullWidth>
          <h4 className="content-title mt-4">{profileTitle}</h4>
          <TextField
            label="自我介紹"
            id="intro"
            name="intro"
            value={values.intro}
            onChange={handleChange}
            multiline
          />
          <TextField
            label={"姓名"}
            id={"name"}
            name={"name"}
            value={values.name}
            onChange={handleChange}
            className={"mt-3"}
          />

          <TextField
            label={"暱稱"}
            id={"nickname"}
            name={"nickname"}
            value={values.nickname}
            onChange={handleChange}
            className={"mt-3"}
          />

          <TextField
            id="birthday"
            type={"date"}
            name="birthday"
            value={values.birthday}
            onChange={handleChange}
            className="mt-3"
          />

          <FormLabel id="gender" className="mt-3">
            性別
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender"
            name="gender"
            onChange={handleChange}
          >
            <FormControlLabel value="0" control={<Radio />} label="女性" />
            <FormControlLabel value="1" control={<Radio />} label="男性" />
          </RadioGroup>

          <Typography id="height" gutterBottom className="mt-3">
            身高（cm）
          </Typography>
          <div className="text-center">
            <Slider
              min={100}
              max={250}
              defaultValue={100}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "height", type: "number", value: value },
                });
              }}
              aria-labelledby="height"
              aria-label="Small"
              valueLabelDisplay="auto"
              style={{ width: "90%" }}
            />
          </div>
          <Typography id="weight" gutterBottom className="mt-3">
            體重（kg）
          </Typography>
          <div className="text-center">
            <Slider
              min={30}
              max={200}
              defaultValue={30}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "weight", type: "number", value: value },
                });
              }}
              aria-labelledby="weight"
              aria-label="Small"
              valueLabelDisplay="auto"
              style={{ width: "90%" }}
            />
          </div>

          <Autocomplete
            id="blood"
            options={Object.keys(blood)}
            getOptionLabel={(option) => blood[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "blood", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {blood[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="血型" />}
            className="mt-3"
          />

          <TextField
            label={"職業"}
            id={"occupation"}
            name="occupation"
            className="mt-3"
            onChange={handleChange}
          />

          <Typography id="salary" gutterBottom className="mt-3">
            年收入
          </Typography>
          <Slider
            min={1}
            max={5}
            defaultValue={1}
            marks={[
              { value: 1, label: "<30萬" },
              { value: 2, label: ">30萬" },
              { value: 3, label: ">100萬" },
              { value: 4, label: ">500萬" },
              { value: 5, label: ">2000萬" },
            ]}
            getAriaValueText={(value) => value.label}
            onChange={handleChange}
            name="salary"
            aria-labelledby="salary"
            aria-label="Small"
            className="mx-3"
            style={{ width: "90%" }}
          />

          <Typography id="asset" gutterBottom className="mt-3">
            資產
          </Typography>
          <Slider
            min={1}
            max={5}
            defaultValue={1}
            marks={[
              { value: 1, label: "<100萬" },
              { value: 2, label: ">100萬" },
              { value: 3, label: ">500萬" },
              { value: 4, label: ">2000萬" },
              { value: 5, label: ">1億" },
            ]}
            getAriaValueText={(value) => value.label}
            onChange={handleChange}
            name={"asset"}
            aria-labelledby="asset"
            aria-label="Small"
            className="mx-3"
            style={{ width: "90%" }}
          />

          <Autocomplete
            id="education"
            options={Object.keys(education)}
            getOptionLabel={(option) => education[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "education", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {education[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="學歷" />}
            className="mt-3"
          />

          <Autocomplete
            multiple
            disableCloseOnSelect
            id="language"
            options={Object.keys(language)}
            getOptionLabel={(option) => language[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "language", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {language[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="語言" />}
            className="mt-3"
          />

          <Autocomplete
            multiple
            id="hobby"
            options={Object.keys(hobby)}
            disableCloseOnSelect
            getOptionLabel={(option) => hobby[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "hobby", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {hobby[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="興趣" />}
            className="mt-3"
          />

          <Autocomplete
            id="religion"
            options={Object.keys(religion)}
            disableCloseOnSelect
            getOptionLabel={(option) => religion[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "religion", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {religion[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="宗教" />}
            className="mt-3"
          />

          <Autocomplete
            multiple
            id="tag"
            freeSolo
            options={[]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "tag", value: value },
              });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, i) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ i })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="filled" label="標籤" />
            )}
            className="mt-3"
          />

          <div className="text-end">
            <ColorButton
              color={"#fff"}
              className="default-btn p-3"
              backgroundColor={themeColor("blueGreen", 1).hex}
              label="繼續"
              onClick={handleSubmit}
            />
          </div>
        </FormControl>
      </div>
      {loading && <CircularLoading />}
    </>
  );
}
