import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ColorButton from "../library/component/button/colorButton";
import { UserIconAddRounded } from "../library/component/frame/userIconAdd";
import { themeColor } from "../library/component/generalConst/generalConst";
import socket from "../library/config/socket";
import { MobileTitleHeader } from "../library/component/header/mobileReturnHeader";

export default function MatchMakerEdit() {
  const imageRef = useRef();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    birthday: "",
    gender: "",
    image: "",
    bankAccountName: "",
    bankAccountNumber: "",
  });

  useEffect(() => {
    socket.emit("matchMakerInfo").once("matchMakerInfoRes", (data) => {
      if (data.status) {
        data.message.image = new Blob([data.message.image]);
        setValues(data.message);
      }
    });
  }, []);

  const handleChange = (e) => {
    let value;
    if (e.target.name === "image") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleSubmit = () => {
    socket
      .emit("updateMatchMaker", values)
      .once("updateMatchMakerRes", (data) => {
        if (data.status) {
          navigate("/");
        }
      });
  };

  const handleClickFile = () => {
    imageRef.current.click();
  };

  return (
    <section className="log-reg">
      <div className="container">
        <div className="main-content">
          <MobileTitleHeader returnButton title={"編輯資料"} />
          <FormControl fullWidth>
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center">
                <button
                  className="img-btn mt-5  position-relative"
                  onClick={() => {
                    handleClickFile();
                  }}
                >
                  <UserIconAddRounded
                    image={
                      values.image ? URL.createObjectURL(values.image) : ""
                    }
                    addIconColor={themeColor("blueGreen", 1).hex}
                  />
                </button>
              </div>
              <input
                className="d-none"
                ref={imageRef}
                type="file"
                name="image"
                accept="image/*"
                onChange={handleChange}
              />
            </div>

            <TextField
              className="mt-3"
              label={"名稱 *"}
              id="name"
              name="name"
              type={"text"}
              value={values.name}
              onChange={handleChange}
            />
            <FormLabel className="mt-3" id="birthday">
              出生日期
            </FormLabel>
            <TextField
              aria-aria-labelledby="birthday"
              type={"date"}
              name="birthday"
              value={values.birthday}
              onChange={handleChange}
            />
            <FormLabel className="mt-3" id="gender">
              性別
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              onChange={handleChange}
              value={values.gender}
            >
              <FormControlLabel value="0" control={<Radio />} label="女性" />
              <FormControlLabel value="1" control={<Radio />} label="男性" />
            </RadioGroup>

            <FormLabel className="mt-3" id="bankAccountName">
              銀行戶口
            </FormLabel>
            <TextField
              label={"銀行戶口名稱 *"}
              name="bankAccountName"
              value={values.bankAccountName}
              onChange={handleChange}
              className="mt-3"
            />
            <TextField
              label={"銀行戶口號碼 *"}
              name="bankAccountNumber"
              type={"number"}
              value={values.bankAccountNumber}
              onChange={handleChange}
              className="mt-3"
            />
          </FormControl>
          <div className="text-end mb-5 mt-3">
            <ColorButton
              className="default-btn"
              label={"完成"}
              onClick={handleSubmit}
              backgroundColor={themeColor("blueGreen", 1).hex}
              color={"#fff"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
