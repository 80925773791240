import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "././assets/css/style.css";
import "././assets/css/animate.css";
import "././assets/css/icofont.min.css";
import "././assets/css/all.min.css";
import "./main.css";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
