import React, { useEffect, useState, useRef } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import socket from "../library/config/socket";
import { now } from "../library/function/dateTime";
import { MobileTitleHeader } from "../library/component/header/mobileReturnHeader";
import { Buffer } from "buffer";
import { themeColor } from "../library/component/generalConst/generalConst";
import { genderColor } from "../library/themeSetting/setting";
import MessageBar from "../library/section/chat/messageBar";
export default function Chat() {
  const auth = useOutletContext();
  const scrollToBottomRef = useRef();
  const [searchParams] = useSearchParams();
  const [chat, setChat] = useState({
    othersUsername: "",
    othersImage: "",
    selfImage: "",
    messages: [],
    othersNickname: "",
  });
  const [text, setText] = useState("");
  useEffect(() => {
    socket.emit("chatRoom", { id: searchParams.get("id") }).once("chatMessage", (data) => {
      data.messages = data.messages.reverse();
      setChat(data);
    });
  }, []);
  useEffect(() => {
    scrollToBottomRef.current.scrollIntoView({
      block: "nearest",
    });
  }, [chat.messages]);
  socket.on("newChatMessage", (data) => {
    let newMessage = [...chat.messages];
    newMessage.push(data);
    let currentChat = { ...chat };
    currentChat.messages = newMessage;
    setChat(currentChat);
  });
  const handleSendMessage = (e) => {
    e.preventDefault();
    let newMessage = {
      sender: auth.username,
      receiver: chat.othersUsername,
      message: { text: text },
      dateTime: now(),
    };
    socket.emit("sendMessage", newMessage);
    let newMessages = [...chat.messages];
    newMessages.push(newMessage);
    setChat({ ...chat, messages: newMessages });
    setText("");
  };
  return (
    <section className="log-reg">
      <MobileTitleHeader returnButton title={chat.othersNickname} />
      <div
        style={{
          height: "calc(100vh - 60px - 60px - 60px)",
          flexDirection: "column",
          justifyContent: "end",
          overflowY: "scroll",
        }}
      >
        {chat.messages.map((e, i) => {
          let flexDirection = "flex-row-reverse me-3";
          let userIcon;
          let borderRadius = "20px 20px 0px 20px";
          if (e.sender === chat.othersUsername) {
            flexDirection = "";
            userIcon = (
              <img
                className="icon-frame "
                src={`data:image/jpeg;base64,${new Buffer.from(
                  chat.othersImage
                ).toString("base64")}`}
                style={{ aspectRatio: "1/1", height: "45px" }}
                alt={"image"}
              />
            );
            borderRadius = "20px 20px 20px 0px";
          }
          return (
            <div
              key={i}
              className={`my-2 d-flex align-items-end ${flexDirection}`}
            >
              {userIcon}
              <div
                className="mx-1 py-1"
                style={{
                  padding: "0 10px 0 10px",
                  maxWidth: "75%",
                  backgroundColor: themeColor[0],
                  borderRadius: borderRadius,
                  overflowWrap: "break-word",
                  overflow: "none",
                }}
              >
                {e.message.text}
              </div>
            </div>
          );
        })}
        <div ref={scrollToBottomRef} className="p-1"></div>
      </div>
      <MessageBar
        buttonColor={genderColor[auth.gender]}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onSubmit={(e) => {
          handleSendMessage(e);
        }}
      />
    </section>
  );
}
