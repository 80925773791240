import {
  RecaptchaVerifier,
  getIdToken,
  signInWithPhoneNumber,
} from "firebase/auth";
import React, { useState } from "react";
import { FormControl, FormLabel, TextField } from "@mui/material";
import { auth } from "../../firebase-config";
import socket from "../../library/config/socket";
import {
  numberFieldPrevent,
  themeColor,
} from "../../library/component/generalConst/generalConst";
import { countryCode } from "../../library/component/countryCode/countryCode";
import Loading from "../../library/component/loading/circularLoading";
import { useNavigate } from "react-router-dom";
import ColorButton from "../../library/component/button/colorButton";
export default function RegisterAccountInfo() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    countryCode: "+852",
    phone: "",
    password: "",
    confirmPassword: "",
    iCode: "",
  });
  const [phoneErr, setPhoneErr] = useState("");
  const [confirmationResult, setConfirmationResult] = useState();
  const [verificationCode, setVerficationCode] = useState();
  const [verificationCodeErr, setVerficationCodeErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendVerification = (e) => {
    let phoneNum = values.countryCode + values.phone;
    if (phoneNum) {
      socket
        .emit("checkAccountExist", { phone: values.phone })
        .once("checkAccountExistRes", (data) => {
          if (data.status) {
            // signInWithPhoneNumber(
            //   auth,
            //   phoneNum,
            //   new RecaptchaVerifier(
            //     "recaptcha",
            //     {
            //       size: "invisible",
            //       callback: (response) => {
            //         console.log(response);
            //       },
            //     },
            //     auth
            //   )
            // )
            //   .then((confirmationResult) => {
            //     setConfirmationResult(confirmationResult);
            //   })
            //   .catch((error) => {
            //     setPhoneErr("電話格式不正確, 請重新輸入");
            //     console.log(error);
            //   });
          } else {
            setPhoneErr("此電話號碼已被註冊");
          }
        });
    }
  };

  const handleVerify = () => {
    // confirmationResult.confirm(verificationCode).then((result) => {
    //   let token = getIdToken(result.user);
      socket
        .emit("getIdToken",{phone:values.phone})
        .once("getIdTokenRes", (data) => {
          if (!data.status) {
            setVerficationCodeErr(true);
          }
        // });
    });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    socket
      .emit("registerAccountInfo", {
        ...values,
        confirmationResult: confirmationResult,
        verificationCode: verificationCode,
      })
      .once("registerAccountInfoRes", (data) => {
        if (data.status) {
          navigate(data.message);
        }
      });
  };

  return (
    <div className="container">
      <FormControl fullWidth>
        <FormLabel className="mt-3" id="phoneLabel">
          電話號碼
        </FormLabel>
        <div className="d-flex align-items-center" aria-labelledby="phoneLabel">
          <TextField
            select
            SelectProps={{ native: true }}
            id={"countryCode"}
            className="w-auto me-3"
            defaultValue={"+852"}
            onChange={handleChange}
          >
            {countryCode.map((e, i) => (
              <option key={i} value={e.code}>
                {e.abbr}
              </option>
            ))}
          </TextField>
          <TextField
            label={"電話號碼 *"}
            id={"phone"}
            name={"phone"}
            type={"number"}
            value={values.phone}
            error={phoneErr}
            helperText={phoneErr}
            onWheel={(e) => {
              e.target.blur();
            }}
            onKeyDown={(e) => {
              if (
                numberFieldPrevent.includes(e.key) ||
                e.key === "ArrowDown" ||
                e.key === "ArrowUp"
              ) {
                e.preventDefault();
              }
            }}
            onChange={handleChange}
            className={"col"}
          />
        </div>
        {/* <div className="d-flex align-items-center mt-3">
          <TextField
            label={"驗證碼 *"}
            id={"verificationCode"}
            name={"verificationCode"}
            type={"number"}
            error={verificationCodeErr}
            helperText={verificationCodeErr && "驗證碼輸入錯誤"}
            value={verificationCode}
            onWheel={(e) => {
              e.target.blur();
            }}
            onBlur={handleVerify}
            onKeyDown={(e) => {
              if (
                numberFieldPrevent.includes(e.key) ||
                e.key === "ArrowDown" ||
                e.key === "ArrowUp"
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setVerficationCode(e.target.value);
            }}
            className={"col"}
          />

          <button
            id="recaptcha"
            className="w-auto ms-3"
            onClick={sendVerification}
          >
            取得驗證碼
          </button>
        </div> */}

        <TextField
          id={"password"}
          type={"password"}
          name="password"
          value={values.password}
          onChange={handleChange}
          label={"密碼"}
          className={"mt-3"}
        />
        <TextField
          type={"password"}
          name={"confirmPassword"}
          value={values.confirmPassword}
          onChange={handleChange}
          label={"確認密碼"}
          className={"mt-3"}
        />
        <TextField
          id={"iCode"}
          value={values.iCode}
          onChange={handleChange}
          label={"邀請碼"}
          className={"mt-3"}
          name="iCode"
        />
      </FormControl>
      <div className="text-center mt-3">
        <ColorButton
          label={"提交"}
          color={"#FFF"}
          className="default-btn p-4"
          backgroundColor={themeColor("blueGreen").hex}
          onClick={handleSubmit}
        />
      </div>
      {loading && <Loading />}
    </div>
  );
}
