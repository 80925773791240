const { io } = require("socket.io-client");

const domain =
  window.location.hostname === "localhost" ||
  window.location.hostname === "192.168.0.101" ||
  window.location.hostname === "192.168.0.100"
    ? window.location.hostname + ":3001"
    : "server." + window.location.hostname;
console.log(domain);
const socket = io(window.location.protocol + "//" + domain + "/", {
  withCredentials: true,
});
export default socket;
