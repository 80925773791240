import React from "react";
import { useNavigate } from "react-router-dom";

export default function BottomTab({ map }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="container border"
        style={{
          height: "60px",
          position: "fixed",
          left: 0,
          bottom: 0,
          backgroundColor: "#f8f9fa",
          zIndex: 5,
        }}
      >
        <div className="row h-100">
          {map["location"].map((e, i) => (
            <button
              key={i}
              className="col align-items-center img-btn "
              onClick={() => {
                navigate(e);
              }}
            >
              {map["icon"][i]}
              <div className="fs-6">{map["label"][i]}</div>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
