import React from "react"

export default function MessageBar({buttonColor, value, onChange, onSubmit, sendButtonLabel}){
 return(
    <form
        onSubmit={onSubmit}
        className="message-bar d-flex justify-content-between border border-top align-items-center"
        style={{ height: "60px", padding: "10px 20px 10px 20px" }}
    >
    <input
      type={"text"}
      className="send-message border col mx-2"
      style={{
        height: "40px",
        backgroundColor: "#FFF",
        borderRadius: "500px",
      }}
      onChange={onChange}
      value={value}
    />
    <button
      className="img-btn"
      style={{
        backgroundColor: buttonColor,
      }}
    >
      {sendButtonLabel}
    </button>
  </form>
 )
}