export const countryCode = [
  {
    code: "+93",
    name: "Afghanistan",
    abbr: "AF",
  },
  {
    code: "+355",
    name: "Albania",
    abbr: "AL",
  },
  {
    code: "+213",
    name: "Algeria",
    abbr: "DZ",
  },
  {
    code: "+1 684",
    name: "American Samoa",
    abbr: "AS",
  },

  {
    code: "+244",
    name: "Angola",
    abbr: "AO",
  },
  {
    code: "+1 264",
    name: "Anguilla",
    abbr: "AI",
  },
  {
    code: "+1 268",
    name: "Antigua and Barbuda",
    abbr: "AG",
  },
  {
    code: "+54",
    name: "Argentina",
    abbr: "AR",
  },
  {
    code: "+374",
    name: "Armenia",
    abbr: "AM",
  },
  {
    code: "+297",
    name: "Aruba",
    abbr: "AW",
  },

  {
    code: "+61",
    name: "Australia",
    abbr: "AU",
  },

  {
    code: "+43",
    name: "Austria",
    abbr: "AT",
  },
  {
    code: "+994",
    name: "Azerbaijan",
    abbr: "AZ",
  },
  {
    code: "+1 242",
    name: "Bahamas",
    abbr: "BS",
  },
  {
    code: "+973",
    name: "Bahrain",
    abbr: "BH",
  },
  {
    code: "+880",
    name: "Bangladesh",
    abbr: "BD",
  },
  {
    code: "+1 246",
    name: "Barbados",
    abbr: "BB",
  },

  {
    code: "+375",
    name: "Belarus",
    abbr: "BY",
  },
  {
    code: "+32",
    name: "Belgium",
    abbr: "BE",
  },
  {
    code: "+501",
    name: "Belize",
    abbr: "BZ",
  },
  {
    code: "+229",
    name: "Benin",
    abbr: "BJ",
  },
  {
    code: "+1 441",
    name: "Bermuda",
    abbr: "BM",
  },
  {
    code: "+975",
    name: "Bhutan",
    abbr: "BT",
  },
  {
    code: "+591",
    name: "Bolivia",
    abbr: "BO",
  },
  {
    code: "+387",
    name: "Bosnia and Herzegovina",
    abbr: "BA",
  },
  {
    code: "+267",
    name: "Botswana",
    abbr: "BW",
  },
  {
    code: "+55",
    name: "Brazil",
    abbr: "BR",
  },
  {
    code: "+246",
    name: "British Indian Ocean Territory",
    abbr: "IO",
  },
  {
    code: "+359",
    name: "Bulgaria",
    abbr: "BG",
  },
  {
    code: "+226",
    name: "Burkina Faso",
    abbr: "BF",
  },
  {
    code: "+257",
    name: "Burundi",
    abbr: "BI",
  },
  {
    code: "+855",
    name: "Cambodia",
    abbr: "KH",
  },
  {
    code: "+237",
    name: "Cameroon",
    abbr: "CM",
  },
  {
    code: "+1",
    name: "Canada",
    abbr: "CA",
  },
  {
    code: "+238",
    name: "Cape Verde",
    abbr: "CV",
  },
  {
    code: "+ 345",
    name: "Cayman Islands",
    abbr: "KY",
  },
  {
    code: "+236",
    name: "Central African Republic",
    abbr: "CF",
  },
  {
    code: "+235",
    name: "Chad",
    abbr: "TD",
  },
  {
    code: "+56",
    name: "Chile",
    abbr: "CL",
  },
  {
    code: "+86",
    name: "China",
    abbr: "CN",
  },
  {
    code: "+61",
    name: "Christmas Island",
    abbr: "CX",
  },
  {
    code: "+57",
    name: "Colombia",
    abbr: "CO",
  },
  {
    code: "+269",
    name: "Comoros",
    abbr: "KM",
  },
  {
    code: "+242",
    name: "Congo",
    abbr: "CG",
  },
  {
    code: "+682",
    name: "Cook Islands",
    abbr: "CK",
  },
  {
    code: "+506",
    name: "Costa Rica",
    abbr: "CR",
  },
  {
    code: "+385",
    name: "Croatia",
    abbr: "HR",
  },
  {
    code: "+53",
    name: "Cuba",
    abbr: "CU",
  },
  {
    code: "+537",
    name: "Cyprus",
    abbr: "CY",
  },
  {
    code: "+420",
    name: "Czech Republic",
    abbr: "CZ",
  },
  {
    code: "+45",
    name: "Denmark",
    abbr: "DK",
  },
  {
    code: "+253",
    name: "Djibouti",
    abbr: "DJ",
  },
  {
    code: "+1 767",
    name: "Dominica",
    abbr: "DM",
  },
  {
    code: "+1 809",
    name: "Dominican Republic",
    abbr: "DO",
  },
  {
    code: "+593",
    name: "Ecuador",
    abbr: "EC",
  },
  {
    code: "+20",
    name: "Egypt",
    abbr: "EG",
  },
  {
    code: "+503",
    name: "El Salvador",
    abbr: "SV",
  },
  {
    code: "+240",
    name: "Equatorial Guinea",
    abbr: "GQ",
  },
  {
    code: "+291",
    name: "Eritrea",
    abbr: "ER",
  },
  {
    code: "+372",
    name: "Estonia",
    abbr: "EE",
  },
  {
    code: "+251",
    name: "Ethiopia",
    abbr: "ET",
  },
  {
    code: "+298",
    name: "Faroe Islands",
    abbr: "FO",
  },
  {
    code: "+679",
    name: "Fiji",
    abbr: "FJ",
  },
  {
    code: "+358",
    name: "Finland",
    abbr: "FI",
  },
  {
    code: "+33",
    name: "France",
    abbr: "FR",
  },
  {
    code: "+594",
    name: "French Guiana",
    abbr: "GF",
  },
  {
    code: "+689",
    name: "French Polynesia",
    abbr: "PF",
  },
  {
    code: "+241",
    name: "Gabon",
    abbr: "GA",
  },
  {
    code: "+220",
    name: "Gambia",
    abbr: "GM",
  },
  {
    code: "+995",
    name: "Georgia",
    abbr: "GE",
  },
  {
    code: "+49",
    name: "Germany",
    abbr: "DE",
  },
  {
    code: "+233",
    name: "Ghana",
    abbr: "GH",
  },
  {
    code: "+350",
    name: "Gibraltar",
    abbr: "GI",
  },
  {
    code: "+30",
    name: "Greece",
    abbr: "GR",
  },
  {
    code: "+299",
    name: "Greenland",
    abbr: "GL",
  },
  {
    code: "+1 473",
    name: "Grenada",
    abbr: "GD",
  },
  {
    code: "+590",
    name: "Guadeloupe",
    abbr: "GP",
  },
  {
    code: "+1 671",
    name: "Guam",
    abbr: "GU",
  },
  {
    code: "+502",
    name: "Guatemala",
    abbr: "GT",
  },
  {
    code: "+224",
    name: "Guinea",
    abbr: "GN",
  },
  {
    code: "+245",
    name: "Guinea-Bissau",
    abbr: "GW",
  },
  {
    code: "+595",
    name: "Guyana",
    abbr: "GY",
  },
  {
    code: "+509",
    name: "Haiti",
    abbr: "HT",
  },
  {
    code: "+504",
    name: "Honduras",
    abbr: "HN",
  },
  {
    code: "+852",
    name: "Hong Kong",
    abbr: "HK",
  },
  {
    code: "+36",
    name: "Hungary",
    abbr: "HU",
  },
  {
    code: "+354",
    name: "Iceland",
    abbr: "IS",
  },
  {
    code: "+91",
    name: "India",
    abbr: "IN",
  },
  {
    code: "+62",
    name: "Indonesia",
    abbr: "ID",
  },
  {
    code: "+964",
    name: "Iraq",
    abbr: "IQ",
  },
  {
    code: "+353",
    name: "Ireland",
    abbr: "IE",
  },
  {
    code: "+972",
    name: "Israel",
    abbr: "IL",
  },
  {
    code: "+39",
    name: "Italy",
    abbr: "IT",
  },
  {
    code: "+1 876",
    name: "Jamaica",
    abbr: "JM",
  },
  {
    code: "+81",
    name: "Japan",
    abbr: "JP",
  },
  {
    code: "+962",
    name: "Jordan",
    abbr: "JO",
  },
  {
    code: "+7 7",
    name: "Kazakhstan",
    abbr: "KZ",
  },
  {
    code: "+254",
    name: "Kenya",
    abbr: "KE",
  },
  {
    code: "+686",
    name: "Kiribati",
    abbr: "KI",
  },
  {
    code: "+965",
    name: "Kuwait",
    abbr: "KW",
  },
  {
    code: "+996",
    name: "Kyrgyzstan",
    abbr: "KG",
  },
  {
    code: "+371",
    name: "Latvia",
    abbr: "LV",
  },
  {
    code: "+961",
    name: "Lebanon",
    abbr: "LB",
  },
  {
    code: "+266",
    name: "Lesotho",
    abbr: "LS",
  },
  {
    code: "+231",
    name: "Liberia",
    abbr: "LR",
  },
  {
    code: "+423",
    name: "Liechtenstein",
    abbr: "LI",
  },
  {
    code: "+370",
    name: "Lithuania",
    abbr: "LT",
  },
  {
    code: "+352",
    name: "Luxembourg",
    abbr: "LU",
  },
  {
    code: "+261",
    name: "Madagascar",
    abbr: "MG",
  },
  {
    code: "+265",
    name: "Malawi",
    abbr: "MW",
  },
  {
    code: "+60",
    name: "Malaysia",
    abbr: "MY",
  },
  {
    code: "+960",
    name: "Maldives",
    abbr: "MV",
  },
  {
    code: "+223",
    name: "Mali",
    abbr: "ML",
  },
  {
    code: "+356",
    name: "Malta",
    abbr: "MT",
  },
  {
    code: "+692",
    name: "Marshall Islands",
    abbr: "MH",
  },
  {
    code: "+596",
    name: "Martinique",
    abbr: "MQ",
  },
  {
    code: "+222",
    name: "Mauritania",
    abbr: "MR",
  },
  {
    code: "+230",
    name: "Mauritius",
    abbr: "MU",
  },
  {
    code: "+262",
    name: "Mayotte",
    abbr: "YT",
  },
  {
    code: "+52",
    name: "Mexico",
    abbr: "MX",
  },
  {
    code: "+377",
    name: "Monaco",
    abbr: "MC",
  },
  {
    code: "+976",
    name: "Mongolia",
    abbr: "MN",
  },
  {
    code: "+1664",
    name: "Montserrat",
    abbr: "MS",
  },
  {
    code: "+212",
    name: "Morocco",
    abbr: "MA",
  },
  {
    code: "+95",
    name: "Myanmar",
    abbr: "MM",
  },
  {
    code: "+264",
    name: "Namibia",
    abbr: "NA",
  },
  {
    code: "+674",
    name: "Nauru",
    abbr: "NR",
  },
  {
    code: "+977",
    name: "Nepal",
    abbr: "NP",
  },
  {
    code: "+31",
    name: "Netherlands",
    abbr: "NL",
  },
  {
    code: "+599",
    name: "Netherlands Antilles",
    abbr: "AN",
  },
  {
    code: "+687",
    name: "New Caledonia",
    abbr: "NC",
  },
  {
    code: "+64",
    name: "New Zealand",
    abbr: "NZ",
  },
  {
    code: "+505",
    name: "Nicaragua",
    abbr: "NI",
  },
  {
    code: "+227",
    name: "Niger",
    abbr: "NE",
  },
  {
    code: "+234",
    name: "Nigeria",
    abbr: "NG",
  },
  {
    code: "+683",
    name: "Niue",
    abbr: "NU",
  },
  {
    code: "+672",
    name: "Norfolk Island",
    abbr: "NF",
  },
  {
    code: "+1 670",
    name: "Northern Mariana Islands",
    abbr: "MP",
  },
  {
    code: "+47",
    name: "Norway",
    abbr: "NO",
  },
  {
    code: "+968",
    name: "Oman",
    abbr: "OM",
  },
  {
    code: "+92",
    name: "Pakistan",
    abbr: "PK",
  },
  {
    code: "+680",
    name: "Palau",
    abbr: "PW",
  },
  {
    code: "+507",
    name: "Panama",
    abbr: "PA",
  },
  {
    code: "+675",
    name: "Papua New Guinea",
    abbr: "PG",
  },
  {
    code: "+595",
    name: "Paraguay",
    abbr: "PY",
  },
  {
    code: "+51",
    name: "Peru",
    abbr: "PE",
  },
  {
    code: "+63",
    name: "Philippines",
    abbr: "PH",
  },
  {
    code: "+48",
    name: "Poland",
    abbr: "PL",
  },
  {
    code: "+351",
    name: "Portugal",
    abbr: "PT",
  },
  {
    code: "+1 787",
    name: "Puerto Rico",
    abbr: "PR",
  },
  {
    code: "+974",
    name: "Qatar",
    abbr: "QA",
  },
  {
    code: "+262",
    name: "Reunion",
    abbr: "RE",
  },
  {
    code: "+40",
    name: "Romania",
    abbr: "RO",
  },
  {
    code: "+685",
    name: "Samoa",
    abbr: "WS",
  },
  {
    code: "+378",
    name: "San Marino",
    abbr: "SM",
  },
  {
    code: "+966",
    name: "Saudi Arabia",
    abbr: "SA",
  },
  {
    code: "+221",
    name: "Senegal",
    abbr: "SN",
  },
  {
    code: "+248",
    name: "Seychelles",
    abbr: "SC",
  },
  {
    code: "+232",
    name: "Sierra Leone",
    abbr: "SL",
  },
  {
    code: "+65",
    name: "Singapore",
    abbr: "SG",
  },
  {
    code: "+421",
    name: "Slovakia",
    abbr: "SK",
  },
  {
    code: "+386",
    name: "Slovenia",
    abbr: "SI",
  },
  {
    code: "+677",
    name: "Solomon Islands",
    abbr: "SB",
  },
  {
    code: "+27",
    name: "South Africa",
    abbr: "ZA",
  },
  {
    code: "+500",
    name: "South Georgia and the South Sandwich Islands",
    abbr: "GS",
  },
  {
    code: "+34",
    name: "Spain",
    abbr: "ES",
  },
  {
    code: "+94",
    name: "Sri Lanka",
    abbr: "LK",
  },
  {
    code: "+249",
    name: "Sudan",
    abbr: "SD",
  },
  {
    code: "+597",
    name: "Suriname",
    abbr: "SR",
  },
  {
    code: "+268",
    name: "Swaziland",
    abbr: "SZ",
  },
  {
    code: "+46",
    name: "Sweden",
    abbr: "SE",
  },
  {
    code: "+41",
    name: "Switzerland",
    abbr: "CH",
  },
  {
    code: "+992",
    name: "Tajikistan",
    abbr: "TJ",
  },
  {
    code: "+66",
    name: "Thailand",
    abbr: "TH",
  },
  {
    code: "+228",
    name: "Togo",
    abbr: "TG",
  },
  {
    code: "+690",
    name: "Tokelau",
    abbr: "TK",
  },
  {
    code: "+676",
    name: "Tonga",
    abbr: "TO",
  },
  {
    code: "+1 868",
    name: "Trinidad and Tobago",
    abbr: "TT",
  },
  {
    code: "+216",
    name: "Tunisia",
    abbr: "TN",
  },
  {
    code: "+90",
    name: "Turkey",
    abbr: "TR",
  },
  {
    code: "+993",
    name: "Turkmenistan",
    abbr: "TM",
  },
  {
    code: "+1 649",
    name: "Turks and Caicos Islands",
    abbr: "TC",
  },
  {
    code: "+688",
    name: "Tuvalu",
    abbr: "TV",
  },
  {
    code: "+256",
    name: "Uganda",
    abbr: "UG",
  },
  {
    code: "+380",
    name: "Ukraine",
    abbr: "UA",
  },
  {
    code: "+971",
    name: "United Arab Emirates",
    abbr: "AE",
  },
  {
    code: "+44",
    name: "United Kingdom",
    abbr: "GB",
  },
  {
    code: "+1",
    name: "United States",
    abbr: "US",
  },
  {
    code: "+598",
    name: "Uruguay",
    abbr: "UY",
  },
  {
    code: "+998",
    name: "Uzbekistan",
    abbr: "UZ",
  },
  {
    code: "+678",
    name: "Vanuatu",
    abbr: "VU",
  },
  {
    code: "+58",
    name: "Venezuela",
    abbr: "VE",
  },
  {
    code: "+681",
    name: "Wallis and Futuna",
    abbr: "WF",
  },
  {
    code: "+967",
    name: "Yemen",
    abbr: "YE",
  },
  {
    code: "+260",
    name: "Zambia",
    abbr: "ZM",
  },
  {
    code: "+263",
    name: "Zimbabwe",
    abbr: "ZW",
  },
];
