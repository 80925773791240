import React from "react";
import { Link } from "react-router-dom";
import { themeColor } from "../generalConst/generalConst";

export default function SideMenu({
  list = [],
  backgroundColor = "#FFF",
  backgroundImage = null,
  style,
  className,
}) {
  const sidebarStyle = {
    height: "100%",
    top: 0,
    transition: "0.3s",
    padding: "10px 10px 10px 10px",
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage,
    left: 0,
    ...style,
  };

  return (
    <div className={className} style={{ ...sidebarStyle }}>
      <div className="sidebar-content">
        <ul>
          {list.map((e, i) => (
            <li
              className="p-2 "
              key={i}
              onTouchStartCapture={(e) => {
                e.currentTarget.style = {
                  backgroundColor: themeColor("blueGreen", 0.5),
                };
              }}
            >
              <Link to={e.link}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="me-2">{e.icon}</div>
                  <div>{e.label}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
