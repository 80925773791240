import React from "react";
import { AiFillHome, AiFillStar, AiOutlineUserSwitch } from "react-icons/ai";
import { RiMessageFill } from "react-icons/ri";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { CgMenuBoxed } from "react-icons/cg";
import { MdPlace } from "react-icons/md";
export const numberFieldPrevent = ["e", "E", "+", "-", "."];

export const bottomTabMap = {
  guest: {
    label: ["會員", "信息", "活動", "配對", "我的"],
    location: ["/", "/contact", "/special", "/matchSetting", "/profile"],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <AiFillStar color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
  user: {
    label: ["探索", "信息", "活動", "我的"],
    location: ["/explore", "/contact", "/special", "/profile"],
    icon: [
      <AiFillStar color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },

  matchMaker: {
    label: ["會員", "信息", "活動", "配對", "我的"],
    location: [
      "/",
      "/contact",
      "/latestDating",
      "/matchDatingList",
      "/profile",
    ],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <AiOutlineUserSwitch color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
  hostAssistant: {
    label: ["會員", "信息", "活動", "配對", "我的"],
    location: [
      "/",
      "/contact",
      "/latestDating",
      "/matchDatingList",
      "/profile",
    ],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <AiOutlineUserSwitch color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
  host: {
    label: ["會員", "信息", "活動", "配對", "我的"],
    location: [
      "/",
      "/contact",
      "/latestDating",
      "/matchDatingList",
      "/profile",
    ],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <AiOutlineUserSwitch color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
  hostLeader: {
    label: ["會員", "信息", "活動", "配對", "我的"],
    location: [
      "/",
      "/contact",
      "/latestDating",
      "/matchDatingList",
      "/profile",
    ],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <AiOutlineUserSwitch color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
  admin: {
    label: ["會員", "信息", "訂單", "場地", "活動", "我的"],
    location: [
      "/",
      "/contact",
      "/latestDating",
      "/matchDatingList",
      "/profile",
    ],
    icon: [
      <AiFillHome color="grey" size="24px" />,
      <RiMessageFill color="grey" size="24px" />,
      <CgMenuBoxed color="grey" size="24px" />,
      <MdPlace color="grey" size="24px" />,
      <BsFillCalendarEventFill color="grey" size="24px" />,
      <FaUser color="grey" size="24px" />,
    ],
  },
};

export const themeColor = ({ color, opacity }) => {
  if ((color = "blueGreen")) {
    return { hex: "#8DD8CC", rgb: `rgb(141, 216, 204, ${opacity})` };
  }
};

export const gender = {
  0: { tc: "女性", sc: "女性", en: "Female" },
  1: { tc: "男性", sc: "男性", en: "Male" },
};

export const hobby = {
  oa: { tc: "戶外活動", sc: "户外活动", en: "Outdoor Activity" },
  ia: { tc: "室內活動", sc: "室内活动", en: "Indoor Activity" },
  stu: { tc: "學習", sc: "学习", en: "Studying" },
  tra: { tc: "旅遊", sc: "旅游", en: "Travelling" },
  dsh: { tc: "美食", sc: "美食", en: "Dishes" },
  car: { tc: "汽車", sc: "汽车", en: "Car" },
  mus: { tc: "音樂", sc: "音乐", en: "Music" },
  adv: { tc: "冒險", sc: "冒险", en: "Adventure" },
  y: { tc: "瑜珈", sc: "瑜伽", en: "Yoga" },
  ca: { tc: "照顧", sc: "照顾", en: "Caring" },
  gym: { tc: "健身", sc: "健身", en: "gym" },
};

export const weekDay = {
  mon: { tc: "星期一", sc: "星期一", en: "Monday" },
  tue: { tc: "星期二", sc: "星期二", en: "Tuesday" },
  wed: { tc: "星期三", sc: "星期三", en: "Wednesday" },
  thur: { tc: "星期四", sc: "星期四", en: "Thursday" },
  fri: { tc: "星期五", sc: "星期五", en: "Friday" },
  sat: { tc: "星期六", sc: "星期六", en: "Saturday" },
  sun: { tc: "星期日", sc: "星期日", en: "Sunday" },
};

export const sign = {
  Aries:{ tc: "白羊", sc: "白羊", en: "Aries" },
  Taurus:{ tc: "金牛", sc: "金牛", en: "Taurus" },
  Gemini:{ tc: "雙子", sc: "双子", en: "Gemini" },
  Cancer:{ tc: "巨蟹", sc: "巨蟹", en: "Cancer" },
  Leo:{ tc: "獅子", sc: "狮子", en: "Leo" },
  Virgo:{ tc: "處女", sc: "处女", en: "Virgo" },
  Libra:{ tc: "天秤", sc: "天秤", en: "Libra"},
  Scorpion:{ tc: "天蠍", sc: "天蝎", en: "Scorpion" },
  Saggitarius:{ tc: "射手", sc: "射手", en: "Saggitarius" },
  Capripion:{ tc: "摩羯", sc: "摩羯", en: "Capripion" },
  Aquarius:{ tc: "水瓶", sc: "水瓶", en: "Aquarius" },
  Pisces:{ tc: "雙魚", sc: "双鱼", en: "Pisces" },
};

export const language = {
  ct: { tc: "粵語", sc: "粤语", en: "Cantonese" },
  pth: { tc: "普通話", sc: "普通话", en: "Mandarin" },
  en: { tc: "英文", sc: "英文", en: "English" },
  ptg: { tc: "葡萄牙語", sc: "葡萄牙语", en: "Portuguese" },
  ab: { tc: "阿拉伯語", sc: "阿拉伯语", en: "Arabic" },
  f: { tc: "法語", sc: "法语", en: "French" },
  gm: { tc: "德語", sc: "德语", en: "German" },
  sn: { tc: "西班牙語", sc: "西班牙语", en: "Spanish" },
  kr: { tc: "韓文", sc: "韩文", en: "Korean" },
  jp: { tc: "日文", sc: "日文", en: "Japanese" },
  rs: { tc: "俄語", sc: "俄语", en: "Russian" },
  ot: { tc: "其他", sc: "其他", en: "Other" },
};

export const education = {
  bhs: { tc: "高中以下", sc: "高中以下", en: "Below High School" },
  hsod: { tc: "高中或文憑", sc: "高中或文凭", en: "High School or Diploma" },
  b: { tc: "學士", sc: "学士", en: "Bachelor" },
  m: { tc: "碩士", sc: "硕士", en: "Master" },
  d: { tc: "博士", sc: "博士", en: "Doctoral" },
};

export const religion = {
  n: { tc: "無", sc: "无", en: "None" },
  b: { tc: "佛教", sc: "佛教", en: "Buddhism" },
  t: { tc: "道教", sc: "道教", en: "Taonism" },
  chris: { tc: "基督新教", sc: "基督新教", en: "Christianity" },
  cath: { tc: "天主教", sc: "天主教", en: "Catholic" },
  i: { tc: "回教", sc: "回教", en: "Islam" },
};

export const blood = {
  o: { tc: "O型", sc: "O型", en: "O" },
  a: { tc: "A型", sc: "A型", en: "A" },
  b: { tc: "B型", sc: "B型", en: "B" },
  ab: { tc: "AB型", sc: "AB型", en: "AB" },
};
