import React from "react";
export default function InfoCard({ section, infoArray }) {
  return (
    <div className="info-card mb-4">
      <div className="info-card-title">
        <h6>{section}</h6>
      </div>
      <div className="info-card-content">
        <ul className="info-list">
          {infoArray.map((e, i) => (
            <li key={i} style={{ padding: "0 0 10px 0" }}>
              <p className="info-name">{e.title}</p>
              <p className="info-details mb-0">{e.value}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
