import React from "react";
import socket from "../../library/config/socket";
import { genderColor } from "../../library/themeSetting/setting";
import { Icon } from "../../library/component/frame/icon";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";

export default function Team() {
  const [teamMember, setTeamMember] = React.useState([]);
  React.useEffect(() => {
    socket.emit("teamMember").once("teamMemberRes", (data) => {
      if (data.status) {
        setTeamMember(data.message);
      }
    });
  }, []);

  return (
    <div>
      {teamMember.map((e, i) => (
        <div>
          <Icon
            style={{ maxWidth: 50, aspectRatio: 1 / 1 }}
            img={`data:image/png;base64,${new Buffer.from(e.img).toString(
              "base64"
            )}`}
            className="me-2"
          />
          <span className="me-2">{e.name}</span>
          <span className="me-2">{e.age}</span>
          <span className="me-2">
            {e.gender ? (
              <BsGenderMale color={genderColor[e.gender]} />
            ) : (
              <BsGenderFemale color={genderColor[e.gender]} />
            )}
          </span>
        </div>
      ))}
    </div>
  );
}
