// import React, { useState, useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
// import InputField from "../../../components/input/inputField";
// import CheckField from "../../../components/input/checkField";
// import RadioField from "../../../components/input/radioFeild";
// import MultipleInputField from "../../../components/input/multipleField";
// import BottomTab from "../../../components/bottomTab";
// import HeaderOne from "../../../components/layout/header";
// import socket from "../../../components/config/socketConfig";
// import { Slider } from "@mui/material";
// const accTitle = "配對條件";
// const option = {
//   bloodRequired: ["O", "A", "B", "AB"],
//   dateLocation: [
//     "中西區",
//     "東區",
//     "南區",
//     "灣仔區",
//     "九龍城區",
//     "觀塘區",
//     "深水埗區",
//     "黃大仙區",
//     "油尖旺區",
//     "離島區",
//     "葵青區",
//     "北區",
//     "西貢區",
//     "沙田區",
//     "大埔區",
//     "荃灣區",
//     "屯門區",
//     "元朗區",
//   ],
//   salaryMin: 0,
//   dateSurrounding: { tc: [], sc: [], en: [] },
// };
export default function MatchSetting() {
  //   const auth = useOutletContext();
  //   const [values, setValues] = useState({
  //     ageRequired: [],
  //     heightRequired: [],
  //     weightRequired: [],
  //     salaryMin: 0,
  //     dateFee: [],
  //     dateLocation: [],
  //     dateSurrounding: [],
  //     bloodRequired: [],
  //     educationRequired: [],
  //     religionRequired: [],
  //     languageRequired: [],
  //     personalityRequired: [],
  //     netAssetRequired: "",
  //   });
  //   useEffect(() => {
  //     socket.emit("matchSetting").once("matchSettingResponse", (data) => {
  //       setValues(data);
  //     });
  //   }, []);
  //   const handleChange = (e) => {
  //     let value;
  //     if (e.target.type === "checkbox") {
  //       let state = values[e.target.name];
  //       state.indexOf(e.target.value) === -1
  //         ? state.push(e.target.value)
  //         : state.splice(state.indexOf(e.target.value), 1);
  //       value = state;
  //     } else if (e.target.type === "number") {
  //       value = parseInt(e.target.value);
  //     } else {
  //       if (!isNaN(e.target.value)) {
  //         value = parseInt(e.target.value);
  //       } else {
  //         value = e.target.value;
  //       }
  //     }
  //     setValues({
  //       ...values,
  //       [e.target.name]: value,
  //     });
  //   };
  //   const handleSubmit = () => {
  //     if (
  //       values.ageRequired &&
  //       values.weightRequired &&
  //       values.heightRequired &&
  //       values.dateLocation &&
  //       values.dateSurrounding &&
  //       values.languageRequired &&
  //       values.personalityRequired &&
  //       values.yearlyIncomeRequired != null &&
  //       values.netAssetRequired != null
  //     ) {
  //       socket
  //         .emit("updateMatchSetting", values)
  //         .once("updateMatchSettingResponse", (data) => {
  //           if (data.status === true) {
  //             alert("成功修改配對設定");
  //           }
  //         });
  //     } else {
  //       alert("你有資料沒有填好！");
  //     }
  //   };
  //   return (
  //     <>
  //       <HeaderOne />
  //       <div className="container padding-bottom mt-3">
  //         <h4 className="content-title text-center">{accTitle}</h4>
  //         <CheckField
  //           title={"對方血型"}
  //           option={option.bloodRequired}
  //           name={"bloodRequired"}
  //           checked={values.bloodRequired}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望對方的性格是"}
  //           option={option.personalityRequired}
  //           name={"personalityRequired"}
  //           checked={values.personalityRequired}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <Slider
  //           getAriaLabel={() => "年齡範圍"}
  //           value={value}
  //           onChange={handleChange}
  //           valueLabelDisplay="on"
  //           getAriaValueText={valuetext}
  //         />
  //         <MultipleInputField
  //           inputArr={[
  //             {
  //               type: "number",
  //               label: "下限",
  //               placeholder: "eg. 18",
  //               name: "ageRequired_lower",
  //               value: values.ageRequired_lower,
  //             },
  //             {
  //               type: "number",
  //               label: "上限",
  //               placeholder: "eg. 23",
  //               name: "ageRequired_upper",
  //               value: values.ageRequired_upper,
  //             },
  //           ]}
  //           title="年齡範圍"
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <MultipleInputField
  //           inputArr={[
  //             {
  //               type: "number",
  //               label: "下限",
  //               placeholder: "eg. 160",
  //               name: "heightRequired_lower",
  //               value: values.heightRequired_lower,
  //             },
  //             {
  //               type: "number",
  //               label: "上限",
  //               placeholder: "eg. 180",
  //               name: "heightRequired_upper",
  //               value: values.heightRequired_upper,
  //             },
  //           ]}
  //           title="配偶身高(cm)"
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <MultipleInputField
  //           inputArr={[
  //             {
  //               type: "number",
  //               label: "下限",
  //               placeholder: "eg. 45",
  //               name: "weightRequired_lower",
  //               value: values.weightRequired_lower,
  //             },
  //             {
  //               type: "number",
  //               label: "上限",
  //               placeholder: "eg. 60",
  //               name: "weightRequired_upper",
  //               value: values.weightRequired_upper,
  //             },
  //           ]}
  //           title="配偶體重(kg)"
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"學歷要求"}
  //           option={option.educationRequired}
  //           name={"educationRequired"}
  //           checked={values.educationRequired}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"倾向溝通語言"}
  //           option={option.languageRequired}
  //           name={"languageRequired"}
  //           checked={values.languageRequired}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"配偶宗教"}
  //           option={option.religionRequired}
  //           name={"religionRequired"}
  //           checked={values.religionRequired}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"是否接受對方的不良嗜好"}
  //           option={option.badHabitNotAcceptable}
  //           checked={values.badHabitNotAcceptable}
  //           name={"badHabitNotAcceptable"}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <RadioField
  //           title="是否接受對方沒有生育能力？"
  //           option={option.fertilityAcceptable}
  //           name="fertilityAcceptable"
  //           value={[1, 0]}
  //           checked={values.fertilityAcceptable}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <RadioField
  //           title="你是否接受對方曾生育？"
  //           option={option.giveBirthAcceptable}
  //           name="giveBirthAcceptable"
  //           value={[1, 0]}
  //           checked={values.giveBirthAcceptable}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望約會費用由誰負擔？"}
  //           option={option.dateFee}
  //           checked={values.dateFee}
  //           name={"dateFee"}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你想在什麼地點約會？"}
  //           option={option.dateLocation}
  //           name={"dateLocation"}
  //           checked={values.dateLocation}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望約會的環境是什麼？"}
  //           option={option.dateSurrounding}
  //           name={"dateSurrounding"}
  //           checked={values.dateSurrounding}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望婚後住所由誰提供？"}
  //           option={option.marriageLivingLocation}
  //           name={"marriageLivingLocation"}
  //           checked={values.marriageLivingLocation}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望婚後支出由誰負擔？"}
  //           option={option.marriageLivingExpenditure}
  //           name={"marriageLivingExpenditure"}
  //           checked={values.marriageLivingExpenditure}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <CheckField
  //           title={"你希望婚後收入如何分配？"}
  //           option={option.marriageLivingIncomeDistribution}
  //           name={"marriageLivingIncomeDistribution"}
  //           checked={values.marriageLivingIncomeDistribution}
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <InputField
  //           title={"對方的居住面積下限(呎)"}
  //           type={"number"}
  //           name={"livingAreaRequired"}
  //           value={values.livingAreaRequired}
  //           placeholder={"eg. 250"}
  //           onChangeValue={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <InputField
  //           title={"收入下限"}
  //           type={"number"}
  //           name={"salaryMin"}
  //           value={values.salaryMin}
  //           placeholder={"eg. 180000"}
  //           onChangeValue={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <InputField
  //           title={"淨資產下限"}
  //           type={"number"}
  //           name={"netAssetMin"}
  //           value={values.netAssetMin}
  //           placeholder={"eg. 10000000"}
  //           onChangeValue={(e) => {
  //             handleChange(e);
  //           }}
  //         />
  //         <div className="text-center">
  //           <button
  //             className={`default-btn ${!auth.gender && "reverse"}`}
  //             onClick={handleSubmit}
  //           >
  //             <span>確認</span>
  //           </button>
  //         </div>
  //       </div>
  //       <BottomTab role={auth.role} />
  //     </>
  //   );
}
