import React from "react";
export default function ColorButton({
  backgroundColor,
  color,
  onClick,
  label,
  className,
  style
}) {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        ...style
      }}
    >
      <span style={{ color }}>{label}</span>
    </button>
  );
}
