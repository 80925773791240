import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import $ from "jquery";

export const PopUp = forwardRef(({ children, height }, ref) => {
  useEffect(() => {
    setTimeout(() => {
      $(".popup").css("height", height ? height : window.innerHeight);
    }, 200);
  }, []);
  useImperativeHandle(ref, () => ({
    handleClosePopUp() {
      setTimeout(() => {
        $(".popup").css("height", "0");
      }, 200);
    },
  }));

  return (
    <div className="popup"  style={{ zIndex: 6 }}>
      <div className="popup-content p-3">{children}</div>
    </div>
  );
});

export function MiniPopUp({ display, handleClose, children }) {
  return (
    <div style={{ zIndex: 4 }} className={display ? "" : "d-none"}>
      <div className="blocker"></div>
      <div className={"w-75 py-3 container bg-white fixed-middle"}>
        <div className="text-end">
          <button className="img-btn" onClick={handleClose}></button>
        </div>
        {children}
      </div>
         
    </div>
  );
}
