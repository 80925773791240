import React, { useRef, useState,useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ColorButton from "../library/component/button/colorButton";
import { Icon } from "../library/component/frame/icon";
import { UserIconAddSqaure } from "../library/component/frame/userIconAdd";
import { themeColor } from "../library/component/generalConst/generalConst";
import socket from "../library/config/socket";

const maxUpload = 5;
export default function UserImage() {
  const navigate = useNavigate();
  const imageFilesRef = useRef(null);
  const [value, setValue] = useState( [] );
  const [changeFileIndex, setChangeFileIndex] = useState(0);
  useEffect(()=>{
    socket.emit("userImage").once("userImageRes",(data)=>{
      if(data.status){
        console.log(data.message)
        data.message.forEach((e)=>{e.file = new Blob([e.file])})
        setValue(data.message)
      }
    })
  },[])
  const handleClickFile = (i) => {
    imageFilesRef.current.click();
  };

  const handleChange = (e) => {
    setValue([...value,...e.target.files]);
  };

  const handleRemove = (i) => {
    let image = [...value];
    image.splice(i, 1);
    if (i === changeFileIndex && i !== 0) {
      setChangeFileIndex(i - 1);
    }
    setValue( image );
  };

  const handleSubmit = () => {
    socket.emit("userImage", value).once("userImageRes", (data) => {
      if (data.status) {
        navigate("/profile");
      }
    });
  };

  return (
    <>
      <h5 className="content-subtitle">最多可以上傳{maxUpload}張圖片</h5>
      <div className="container">
        <input
          multiple
          ref={imageFilesRef}
          type={"file"}
          name="image"
          className="d-none"
          accept="image/*"
          onChange={handleChange}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <button
          className="img-btn w-100 position-relative"
          onClick={handleClickFile}
        >
          <UserIconAddSqaure
            addIconColor={themeColor("blueGreen").hex}
            addIconSize={"50%"}
            image={
              value.length &&
              URL.createObjectURL(value[changeFileIndex].file)
            }
          />
        </button>

        <div className="row row-cols-5 mt-3">
          {value.map((e, i) => (
            <div key={i} className="position-relative">
              <div
                onClick={() => {
                  setChangeFileIndex(i);
                }}
              >
                <Icon
                  img={URL.createObjectURL(e.file)}
                  style={{ width: "100%", aspectRatio: "1/1.2" }}
                  borderRadius="10px"
                />
              </div>
              <AiFillCloseCircle
                className="position-absolute translate-middle"
                style={{ top: 0, right: -5 }}
                color={themeColor("blueGreen").hex}
                size={"20"}
                onClick={() => {
                  handleRemove(i);
                }}
              />
            </div>
          ))}
        </div>

        {value.length ? (
          <div className="text-end">
            <ColorButton
              className={"default-btn"}
              onClick={handleSubmit}
              label={"完成"}
              backgroundColor={themeColor("blueGreen").hex}
              color={"#FFF"}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
