import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import ScrollToTop from "./library/component/scrollTop/scrollTop.jsx";
import Login from "./page/login.jsx";
import Match from "./page/explore.jsx";
import {
  CheckSessionExist,
  ProtectedRouteAuthentication,
} from "./library/section/protectedRoute/protectedRoute";
import Explore from "./page/explore.jsx";
import MaxHeight from "./library/component/maxHeight/maxHeight";
import Profile from "./page/profile.jsx";
import MatchSetting from "./page/matchSetting.jsx";
import Team from "./page/team/team.jsx";
import Chat from "./page/chat.jsx";
import TeamSection from "./page/team/teamSection.jsx";

import Member from "./page/team/member.jsx";
import MatchMakerRegister from "./page/register/matchMakerInfo.jsx";
import RegisterUserInfo from "./page/register/userInfo.jsx";
import Header from "./library/component/header/header.jsx";
import { bottomTabMap, themeColor } from "./library/component/generalConst/generalConst.jsx";
import RegisterAccountInfo from "./page/register/accountInfo.jsx";
import RegisterUserImage from "./page/register/userImage.jsx";
import MemberApplication from "./page/team/memberApplication.jsx";
import MatchMakerApplication from "./page/team/matchMakerApplication.jsx";
import UserInfo from "./page/userInfo.jsx";
import MatchMakerEdit from "./page/matchMakerEdit.jsx";
import UserEdit from "./page/userEdit.jsx";
import BottomTab from "./library/component/bottomTab/bottomTab.jsx";
import UserImage from "./page/userImage.jsx";

function MaxHeightRoute({ height }) {
  const auth = useOutletContext();
  return ( <>{height <= window.screen.availHeight - 80 && (
        <Header
          title={
            <div className="d-flex align-items-center">
              <img src="./favicon.png" style={{ width: 24 }} alt={"logo"} />
              緣滿
            </div>
          }
          style={{ backgroundColor: themeColor("blueGreen").hex }}
        />
      )}
    <MaxHeight height={height}>
    

      <Outlet context={auth} />
      {height ===window.screen.availHeight - 60 - 72 && <BottomTab map={bottomTabMap["user"]} />}
    </MaxHeight></> 
  );
}
export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<CheckSessionExist />}>
           <Route
            element={<MaxHeightRoute height={window.screen.availHeight - 80} />}
          >
            <Route path="/" element={<Login />} />
            <Route path="register" element={<RegisterAccountInfo />} />
            <Route path="registerMatchMaker" element={<MatchMakerRegister />} />
            <Route path="registerUser" element={<RegisterUserInfo />} />
            <Route path="registerUserImage" element={<RegisterUserImage />} />
            <Route path="matchSetting" element={<MatchSetting />} />
            <Route path="chat" element={<Chat />} />
          </Route>

          <Route
            element={<MaxHeightRoute height={window.screen.availHeight - 60} />}
          >
            <Route path="userInfo" element={<UserInfo />} />
            <Route path="userEdit" element={<UserEdit />} />
            <Route path="userImage" element={<UserImage />} />
           {/* <Route path="matchMakerInfo" element={<MatchMakerInfo />} /> */}
            <Route path="matchMakerEdit" element={<MatchMakerEdit />} />
          </Route>
          <Route element={<TeamSection />}>
            <Route path="team" element={<Team />} />
            <Route path="member" element={<Member />} />
            <Route path="memberApplication" element={<MemberApplication />} />
            <Route
              path="matchMakerApplication"
              element={<MatchMakerApplication />}
            />
          </Route>

          <Route
            element={
              <MaxHeightRoute
                height={window.screen.availHeight - 60 - 72}
              />
            }
          >
            <Route element={<ProtectedRouteAuthentication />}>
              <Route path="profile" element={<Profile />} />
              <Route path="match" element={<Match />} />
              <Route path="explore" element={<Explore />} />
            </Route>
          </Route>
        </Route> 
      </Routes>
    </Router>
  );
}
