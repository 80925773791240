import React, { Fragment, useEffect, useState } from "react";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ColorButton from "../../library/component/button/colorButton";
import { Icon } from "../../library/component/frame/icon";
import { themeColor } from "../../library/component/generalConst/generalConst";
import socket from "../../library/config/socket";
import { genderColor } from "../../library/themeSetting/setting";

export default function MatchMakerApplication() {
  const navigate = useNavigate();
  const [matchMakerApplication, setMatchMakerApplication] = useState([]);
  useEffect(() => {
    socket
      .emit("matchMakerApplication")
      .once("matchMakerApplicationRes", (data) => {
        setMatchMakerApplication(data.message);
      });
  }, []);

  const handleClick = (i) => {
    navigate({ pathname: "matchMakerInfo", search: "?=" + i });
  };

  const handleApprove = (i) => {
    let state = [...matchMakerApplication];
    socket.emit("confirmMatchMakerApplication", { id: state[i].user_id }).once("confirmMatchMakerApplicationRes", (data) => {
      if (data.status === true) {
        state.splice(i, 1);
        setMatchMakerApplication(state);
      }
    });
  };

  return (
    <div className="container">
      {matchMakerApplication.map((e, i) => (
        <Fragment key={i}>
          <div
            className="row"
            onClick={() => {
              handleClick(e.user_id);
            }}
          >
            <div className="middle-content w-100">
              <div>
                <Icon
                  style={{ maxWidth: 50, aspectRatio: 1 / 1 }}
                  img={`data:image/png;base64,${new Buffer.from(e.img).toString(
                    "base64"
                  )}`}
                  className="me-2"
                />
                <span className="me-2">{e.name}</span>
                <span className="me-2">{e.age}</span>
                <span className="me-2">
                  {e.gender ? (
                    <BsGenderMale color={genderColor[e.gender]} />
                  ) : (
                    <BsGenderFemale color={genderColor[e.gender]} />
                  )}
                </span>
              </div>
              <div className=" text-end">
                <ColorButton
                  color="#FFF"
                  backgroundColor={themeColor("blueGreen").hex}
                  label="確認"
                  onClick={() => {
                    handleApprove(i);
                  }}
                  className={`default-btn mt-2`}
                />
              </div>
            </div>
          </div>
          <hr />
        </Fragment>
      ))}
    </div>
  );
}
