import React from "react";

export default function RoundedTheme({ backgroundColor, height, children }) {
  return (
    <div
      style={{
        borderRadius: "0 0 45% 45%",
        backgroundColor: backgroundColor ? backgroundColor : "#FFF",
        width: "100%",
        height: height ? height : "25%",
      }}
    >
      {children}
    </div>
  );
}
