import React from "react";
import { useNavigate } from "react-router-dom";
import Circle from "../layout/circle";

export default function HorizontalMenu({ menu = [] }) {
  const navigate = useNavigate();
  return (
    <div className={"row row-cols-auto justify-content-center"}>
      {menu.map((e, i) => (
        <Circle
          key={i}
          style={{ margin: "0 10px 0 10px" }}
          onClick={() => {
            navigate(e.link);
          }}
        >
          <div className="d-flex align-content-center p-1">{e.icon}</div>
        </Circle>
      ))}
    </div>
  );
}
