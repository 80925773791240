import React, { Fragment, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import socket from "../../config/socket";
import AxiosClient from "../../config/axios";

export const CheckSessionExist = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    userId: undefined,
  });
  useEffect(() => {
    AxiosClient.post("authent");
    socket.emit("authent").once("authentRes", (data) => {
      if (!data.sessionExist) {
        window.location.reload();
      }else{
        setAuthentication(data)
      }
    });
  }, []);

  return (
    <Fragment>
      <Outlet context={authentication} />
    </Fragment>
  );
};
export const ProtectedRouteAuthentication = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    user_id: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);
  if (authentication.authentication !== undefined) {
    if (authentication.authentication === true) {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
          <Outlet context={authentication} />
        </Fragment>
      );
    }
  }
};

export const ProtectedRouteUser = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    username: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);
  if (authentication.authentication !== undefined) {
    if (authentication.role === "user") {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
        </Fragment>
      );
    }
  }
};
export const ProtectedRouteMatchMaker = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    gender: undefined,
    username: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);

  if (authentication.authentication !== undefined) {
    if (
      authentication.role === "matchMaker" ||
      authentication.role === "host" ||
      authentication.role === "hostAssistant" ||
      authentication.role === "hostLeader"
    ) {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
        </Fragment>
      );
    }
  }
};

export const ProtectedRouteHost = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    gender: undefined,
    username: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);
  if (authentication.authentication !== undefined) {
    if (
      authentication.role === "host" ||
      authentication.role === "hostAssistant" ||
      authentication.role === "hostLeader"
    ) {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
        </Fragment>
      );
    }
  }
};

export const ProtectedSiteOwner = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    gender: undefined,
    username: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);
  if (authentication.authentication !== undefined) {
    if (authentication.role === "siteOwner") {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
        </Fragment>
      );
    }
  }
};

export const ProtectedRouteAdmin = () => {
  const [authentication, setAuthentication] = useState({
    authentication: undefined,
    role: undefined,
    gender: undefined,
    username: undefined,
  });
  useEffect(() => {
    socket.emit("authent").once("authentRes", (data) => {
      setAuthentication(data);
    });
  }, []);
  if (authentication.authentication !== undefined) {
    if (authentication.role === "admin") {
      return (
        <Fragment>
          <Outlet context={authentication} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/" replace />
        </Fragment>
      );
    }
  }
};
