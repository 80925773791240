import React, { Fragment, useEffect, useState } from "react";
import socket from "../library/config/socket";
import { Buffer } from "buffer";
import { InfoGrid2 } from "../library/component/grid/InfoGrid";
import { AiOutlineMan, AiOutlineWoman } from "react-icons/ai";
import { MdWork } from "react-icons/md";
import { genderColor } from "../library/themeSetting/setting";
import { useOutletContext } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import Filter from "../library/component/filter/filter";

export default function Explore() {
  const auth = useOutletContext();
  const [recList, setRecList] = useState([]);
  useEffect(() => {
    socket.emit("recList").once("recListRes", (data) => {
      setRecList(data.message);
    });
  }, []);
  const handleRefresh = () => {
    socket.emit("recList").once("recListRes", (data) => {
      setRecList(data.message);
    });
  };

  return (
    <>
      <div>
        <div className="d-flex" style={{ padding: "30px 0px" }}>
          <h3 className="mx-auto">推薦列表</h3>
          <div className="ms-auto">
            <button className="img-btn" onClick={handleRefresh}>
              <BiRefresh size={30} />
            </button>
            <Filter
              onChange={(value) => {
                console.log(value);
              }}
            />
          </div>
        </div>
        {recList.map((e, i) => (
          <Fragment key={i}>
            <InfoGrid2
              img={
                `data:image/jpg;base64,` +
                new Buffer.from(e.img[0]).toString("base64")
              }
              redirectTo={`/userInfo?user_id=${e.id}`}
              title={e.nickname}
              description={
                <>
                  <div>
                    <span className="me-3">
                      {e.age}
                      {e.gender ? (
                        <AiOutlineMan color={genderColor[e.gender]} size={16} />
                      ) : (
                        <AiOutlineWoman
                          color={genderColor[e.gender]}
                          size={16}
                        />
                      )}
                    </span>
                    <span>
                      <MdWork size={16} />
                      {e.occupation}
                    </span>
                  </div>
                  <div className="d-flex scroll-hide">
                    {e.img.map((e, i) => (
                      <img
                        key={i}
                        src={
                          `data:image/jpg;base64,` +
                          new Buffer.from(e).toString("base64")
                        }
                        style={{
                          objectFit: "cover",
                          border: "1px solid rgb(124, 123, 123)",
                          width: "27%",
                        }}
                        className="me-3"
                        alt={"gallery"}
                      />
                    ))}
                  </div>
                </>
              }
            />
            <hr className="m-0" />
          </Fragment>
        ))}
      </div>
    </>
  );
}
