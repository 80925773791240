import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularLoading() {
  return (
    <div
      className="blocker"
      style={{ zIndex: 2, background: "rgba(0, 0, 0, 0.2)" }}
    >
      <CircularProgress
        className="position-fixed start-50 top-50 translate-middle"
        size={"25%"}
      />
    </div>
  );
}
