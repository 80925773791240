import React, { useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ColorButton from "../../library/component/button/colorButton";
import { Icon } from "../../library/component/frame/icon";
import { UserIconAddSqaure } from "../../library/component/frame/userIconAdd";

import { themeColor } from "../../library/component/generalConst/generalConst";
import socket from "../../library/config/socket";

const maxUpload = 5;
export default function RegisterUserImage() {
  const navigate = useNavigate();
  const imageFilesRef = useRef(null);
  const [value, setValue] = useState({ images: [] });
  const [changeFileIndex, setChangeFileIndex] = useState(0);
  const handleClickFile = (i) => {
    imageFilesRef.current.click();
  };

  const handleChange = (e) => {
    setValue({ images: [...e.target.files] });
  };

  const handleRemove = (i) => {
    let image = [...value.images];
    image.splice(i, 1);
    if (i === changeFileIndex && i !== 0) {
      setChangeFileIndex(i - 1);
    }
    setValue({ images: image });
  };

  const handleSubmit = () => {
    console.log(value);
    socket.emit("registerUserImage", value);
    socket.once("registerUserImageRes", (data) => {
      if (data.status) {
        navigate("/profile");
      }
    });
  };

  return (
    <>
      <h5 className="content-subtitle">最多可以上傳{maxUpload}張圖片</h5>
      <div className="container">
        <input
          multiple
          ref={imageFilesRef}
          type={"file"}
          name="image"
          className="d-none"
          accept="image/*"
          onChange={handleChange}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <button
          className="img-btn w-100 position-relative"
          onClick={handleClickFile}
        >
          <UserIconAddSqaure
            addIconColor={themeColor("blueGreen").hex}
            addIconSize={"50%"}
            image={
              value.images.length &&
              URL.createObjectURL(value.images[changeFileIndex])
            }
          />
        </button>

        <div className="row row-cols-5 mt-3">
          {value.images.map((e, i) => (
            <div key={i} className="position-relative">
              <div
                onClick={() => {
                  setChangeFileIndex(i);
                }}
              >
                <Icon
                  img={URL.createObjectURL(e)}
                  style={{ width: "100%", aspectRatio: "1/1.2" }}
                  borderRadius="10px"
                />
              </div>
              <AiFillCloseCircle
                className="position-absolute translate-middle"
                style={{ top: 0, right: -5 }}
                color={themeColor("blueGreen").hex}
                size={"20"}
                onClick={() => {
                  handleRemove(i);
                }}
              />
            </div>
          ))}
        </div>

        {value.images.length ? (
          <div className="text-end">
            <ColorButton
              className={"default-btn"}
              onClick={handleSubmit}
              label={"完成"}
              backgroundColor={themeColor("blueGreen").hex}
              color={"#FFF"}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
