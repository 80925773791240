import { initializeApp } from "firebase/app";
import { getAuth, PhoneAuthCredential, PhoneAuthProvider } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIaXeey6KGIEVcprn8qFgKHlyjrYd0Beo",
  authDomain: "myspace-social-app.firebaseapp.com",
  projectId: "myspace-social-app",
  storageBucket: "myspace-social-app.appspot.com",
  messagingSenderId: "71480566622",
  appId: "1:71480566622:web:1c0123699aa1be70bba5cf",
  measurementId: "G-SLS5M3Q5G8"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
const provider = new PhoneAuthProvider(auth);

