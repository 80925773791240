import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import socket from "../library/config/socket";
import { Buffer } from "buffer";
import { Icon } from "../library/component/frame/icon";
import { MobileTitleHeader } from "../library/component/header/mobileReturnHeader";
import UserDetail from "../library/section/userInfo/userInfo";
export default function UserInfo() {
  const auth = useOutletContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [imagePosition, setImagePosition] = useState({
    startX: 0,
    x: 0,
    currentX: 0,
  });
  const [user, setUser] = useState({
    id: "",
    gender: "",
    name: "",
    nickname: "",
    age: "",
    height: "",
    weight: "",
    blood: "",
    occupation: "",
    salary: "",
    asset: "",
    location: "",
    education: "",
    religion: "",
    language: [],
    hobby: [],
    image: [],
    tag: [],
    matchMakerName: "",
    matchMakerId: "",
    matchMakerImage: "",
    score: undefined,
    matchUser: { previous: "", next: "" },
  });
  // useScript(
  //   "https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/carousel/carousel.umd.js"
  // );
  // useStyleSheet(
  //   "https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/carousel/carousel.css"
  // );

  const container = document.getElementById("myCarousel");
  useEffect(() => {
    if (searchParams.get("user_id")) {
      socket.emit("userInfo", { userId: searchParams.get("user_id") });
    } else if (searchParams.get("match_id")) {
      socket.emit("userInfo", { matchId: searchParams.get("match_id") });
    } else {
      socket.emit("userInfo");
    }
    socket.once("userInfoRes", (data) => {
      if (data.status) {
        setUser({ ...user, ...data.message });
      }
    });
  }, []);

  const handleLike = () => {
    socket.emit("likeTarget", { userId: user.id });
  };

  const handleDislike = () => {
    socket.emit("dislikeTarget", { userId: user.id });
  };

  const handleSaveTarget = () => {
    socket.emit("saveTarget", { userId: user.id });
  };

  // const handleDate = () => {
  //   if (auth.role === "user") {
  //     navigate({
  //       pathname: "/postDate",
  //       search: `?user=${user.id}`,
  //     });
  //   } else if (auth.role === "matchMaker") {
  //     navigate({
  //       pathname: "/matchMaker/matchDating",
  //       search: `?user=${user.id}`,
  //     });
  //   }
  // };
  // const handleEvent = () => {
  //   navigate({
  //     pathname: "/userEvent",
  //     search: `?user=${user.id}`,
  //   });
  // };
  // const handleMatchDetail = () => {
  //   navigate({
  //     pathname: "/matchDetail",
  //     search: `?user=${user.id}`,
  //   });
  // };

  const handleNavigateMatchMaker = () => {
    navigate({
      pathname: "/matchMaker",
      search: `?matchMaker=${user.matchMakerId}`,
    });
  };

  return (
    <div
      style={{ overflow: "hidden" }}
      onScroll={(e) => {
        e.preventDefault();
      }}
    >
      <MobileTitleHeader returnButton title={user.nickname} />
      <div className="d-flex" style={{ height: "50%" }}>
        {/* <ReactImageGallery
          items={user.image.map((e) => {
            return {
              original: URL.createObjectURL(new Blob([new Buffer.from(e)])),
            };
          })}
          description
          showBullets
          showThumbnails
          additionalClass={"app-image-gallery"}
          autoPlay={false}
          disableKeyDown={false}
          disableSwipe={false}
          disableThumbnailScroll={false}
          disableThumbnailSwipe={false}
          flickThreshold={0.4}
          indexSeparator={" / "}
          infinite={true}
          isRTL={false}
          showPlayButton={false}
          slideDuration={450}
          lazyLoad={false}
          onBeforeSlide={null}
          onBulletClick={null}
          onClick={() => {}}
          onErrorImageURL=""
          onImageError={null}
          onImageLoad={() => {}}
          onMouseLeave={null}
          onMouseOver={null}
          onPause={() => {}}
          onPlay={() => {}}
          onScreenChange={() => {}}
          onSlide={() => {}}
          onThumbnailClick={null}
          onThumbnailError={null}
          onTouchEnd={null}
          onTouchMove={null}
          onTouchStart={null}
          renderCustomControls={null}
          renderFullscreenButton={() => {}}
          renderItem={null}
          renderLeftNav={() => {}}
          renderPlayPauseButton={() => {}}
          renderRightNav={() => {}}
          renderThumbInner={null}
          showFullscreenButton={true}
          showIndex={false}
          showNav={true}
          slideInterval={2000}
          slideOnThumbnailOver={false}
          startIndex={0}
          stopPropagation={false}
          swipeThreshold={30}
          swipingThumbnailTransitionDuration={0}
          swipingTransitionDuration={0}
          thumbnailPosition="bottom"
          useBrowserFullscreen={true}
          useTranslate3D={true}
          useWindowKeyDown={true}
        /> */}
        {/* <div className="f-carousel" id="myCarousel">
          <div className="f-carousel__viewport">
            <div className="f-carousel__track">
              <div className="f-carousel__slide">1</div>
              <div className="f-carousel__slide">2</div>
              <div className="f-carousel__slide">3</div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="member-inner-box">
        <button
          className="matchmaker-info text-center img-btn"
          onClick={() => {
            handleNavigateMatchMaker();
          }}
        >
          <Icon
            img={`data:image/png;base64,${new Buffer.from(
              user.matchMakerImage
            ).toString("base64")}`}
            style={{ width: 60, aspectRatio: 1 / 1 }}
          />
          <div className="matchmaker-level">{user.matchMakerName}</div>
        </button>
        <div className="member-info">
          <div>
            <h2 className="member-name">{user.nickname}</h2>
            <span className="member-age">{user.age}</span>
          </div>
          <div className="member-description">
            {user.tag.map((e, i) => (
              <div key={e + i} className="tag">
                {e}
              </div>
            ))}
          </div>
          {/* {user.gender !== "" &&
            user.gender !== auth.gender &&
            auth.role === "user" && (
              <MatchButtonFunction
                relationship={user.relationship}
                handleLike={() => {
                  handleLike();
                }}
                likeTimes={user.likeTimes}
                gender={user.gender}
                handleDate={() => {
                  handleDate();
                }}
                handleMatchDetail={() => {
                  handleMatchDetail();
                }}
                matchPercentage={user.score * 100}
                handleEvent={() => {
                  handleEvent();
                }}
              />
            )} */}
          {/* {auth.role === "matchMaker" && (
            <div className="d-flex justify-content-center align-items-center pb-3">
              <button className="img-btn col" onClick={handleDate}>
                <img
                  src={"../../assets/images/date.png"}
                  style={{ width: "45px" }}
                  alt={"date"}
                />
              </button>
            </div>
          )} */}
        </div>
      </div>
      <div className="container position-relative mt-2">
        {user.userId ? <UserDetail user={user} /> : ""}
      </div>
    </div>
  );
}
