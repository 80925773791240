import React from "react";

export function Icon({ img, style, borderRadius, className }) {
  return (
    <img
      src={
        img ? img : `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`
      }
      style={{
        objectFit: "cover",
        borderRadius: borderRadius ? borderRadius : "50%",
        border: "1px solid rgb(124, 123, 123)",
        ...style,
      }}
      className={className}
      alt={"icon"}
    />
  );
}
