import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdAddCircle } from "react-icons/md";
import { Icon } from "./icon";

export function UserIconAddRounded({ image, addIconColor }) {
  return (
    <>
      <Icon img={image} style={{ width: "100px", aspectRatio: "1/1" }} />
      <div className="position-absolute" style={{ bottom: 0, right: 5 }}>
        <MdAddCircle color={addIconColor} size={33} />
      </div>
    </>
  );
}

export function UserIconAddSqaure({ image, addIconSize, addIconColor }) {
  return (
    <>
      <Icon
        img={image}
        style={{ width: "100%", aspectRatio: "1/1.2" }}
        borderRadius="10px"
      />
      <div className="absolute-middle">
        {image ? (
          ""
        ) : (
          <AiOutlinePlus
            color={addIconColor}
            size={addIconSize ? addIconSize : 33}
          />
        )}
      </div>
    </>
  );
}
