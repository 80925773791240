import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export function MobileTitleHeader({ title, returnButton, tools = [] }) {
  const navigate = useNavigate();
  return (
    <header
      className="nav position-sticky container text-center py-3 theme-title-2 position-relative px-2"
      style={{ height: "60px", backgroundColor: "#f1f7fe" }}
    >
      {returnButton && (
        <button
          className="img-btn absolute-left ms-3"
          style={{ fontSize: "24px" }}
          onClick={() => {
            typeof returnButton === "string"
              ? navigate(returnButton)
              : navigate(-1);
          }}
        >
          <BiArrowBack size={"20px"} />
        </button>
      )}
      <div className="absolute-middle text-muted fs-5">{title}</div>
      <div className="absolute-right me-3" >
        {tools.map((e) => (
          <button className="img-btn " onClick={e.func}>
            {e.icon}
          </button>
        ))}
      </div>
    </header>
  );
}
