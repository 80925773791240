import React, { useState } from "react";
import InfoCard from "../../component/infoCard/infoCard";
import {
  blood,
  education,
  gender,
  hobby,
  language,
  religion,
} from "../../component/generalConst/generalConst";

export default function UserDetail({ user }) {
  const section = [
    {
      name: "用戶信息",
      info: [
        { title: "名稱:", value: user.name },
        { title: "暱稱:", value: user.nickname },
        { title: "年齡:", value: user.age },
        { title: "性別:", value: gender[user.gender]["tc"] },
        { title: "身高:", value: `${user.height}cm` },
        { title: "體重:", value: `${user.weight}kg` },
        { title: "血型:", value: blood[user.blood].tc },
        { title: "教育:", value: education[user.education]["tc"] },
        { title: "宗教:", value: religion[user.religion]["tc"] },
        {
          title: "語言:",
          value: user.language.map((e) => language[e]["tc"]).join(", "),
        },
        {
          title: "興趣:",
          value: user.hobby.map((e) => hobby[e].tc).join(", "),
        },
        { title: "地區:", value: user.location },
        { title: "職位:", value: user.occupation },
        { title: "收入:", value: user.salary },
        { title: "資產:", value: user.asset },
      ],
    },
  ];
  const [current, setCurrent] = useState(0);
  return (
    <div className="info">
      <InfoCard
        section={section[current].name}
        infoArray={section[current].info}
      />
    </div>
  );
}
