import React from "react";

export default function Circle({ children, radius, style, onClick }) {
  return (
    <div
      className="border position-relative d-flex align-content-center"
      style={{
        backgroundColor: "grey",
        borderRadius: "50%",
        width: radius,
        aspectRatio: 1 / 1,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
