import React, { Fragment, useEffect, useState } from "react";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ColorButton from "../../library/component/button/colorButton";
import { Icon } from "../../library/component/frame/icon";
import { themeColor } from "../../library/component/generalConst/generalConst";
import socket from "../../library/config/socket";
import { genderColor } from "../../library/themeSetting/setting";

export default function MemberApplication() {
  const navigate = useNavigate();
  const [memberApplication, setMemberApplication] = useState([]);
  useEffect(() => {
    socket.emit("memberApplication").once("memberApplicationRes", (data) => {
      if (data.status) {
        setMemberApplication(data.message);
      }
    });
  }, []);

  const handleClick = (i) => {
    navigate({ pathname: "/userInfo", search: "?user_id=" + i });
  };

  const handleApprove = (i) => {
    let state = [...memberApplication];

    socket
      .emit("approveUserApplication", { userId: state[i].userId })
      .once("approveUserApplicationRes", (data) => {
        if (data.status === true) {
          state.splice(i, 1);
          setMemberApplication(state);
        }
      });
  };

  return (
    <div className="container">
      {memberApplication.map((e, i) => (
        <Fragment key={i}>
          <div
            className="row"
            onClick={() => {
              handleClick(e.userId);
            }}
          >
            <div className="middle-content w-100">
              <div>
                <Icon
                  style={{ maxWidth: 50, aspectRatio: 1 / 1 }}
                  img={`data:image/png;base64,${new Buffer.from(e.img).toString(
                    "base64"
                  )}`}
                  className="me-2"
                />
                <span className="me-2">{e.name}</span>
                <span className="me-2">{e.age}</span>
                <span className="me-2">
                  {e.gender ? (
                    <BsGenderMale color={genderColor[e.gender]} />
                  ) : (
                    <BsGenderFemale color={genderColor[e.gender]} />
                  )}
                </span>
              </div>
              <div className=" text-end">
                <ColorButton
                  color="#FFF"
                  backgroundColor={themeColor("blueGreen").hex}
                  label="確認"
                  onClick={() => {
                    handleApprove(i);
                  }}
                  className={`default-btn mt-2`}
                />
              </div>
            </div>
          </div>
          <hr />
        </Fragment>
      ))}
    </div>
  );
}
