import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  blood,
  education,
  hobby,
  language,
  religion,
  themeColor,
} from "../library/component/generalConst/generalConst";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import CircularLoading from "../library/component/loading/circularLoading";
import ColorButton from "../library/component/button/colorButton";
import socket from "../library/config/socket";
import { MenuItem } from "react-contextmenu";
import { MobileTitleHeader } from "../library/component/header/mobileReturnHeader";
import { AiOutlineCheck } from "react-icons/ai";

export default function UserEdit() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    nickname: "",
    birthday: "",
    gender: "",
    salary: "1",
    education: "",
    hobby: [],
    asset: "1",
    religion: "",
    language: [],
    occupation: "",
    blood: "",
    weight: "",
    height: "",
    location: [],
    intro: "",
    tag: "",
  });
  useEffect(() => {
    socket.emit("userEditInfo").once("userEditInfoRes", (data) => {
      if (data.status) {
        setValues({
          ...values,
          ...data.message,
        });
      }
    });
  }, []);
  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      let state = values[e.target.name];
      state.indexOf(e.target.value) === -1
        ? state.push(e.target.value)
        : state.splice(state.indexOf(e.target.value), 1);
      value = state;
    } else if (e.target.type === "number") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleSubmit = () => {
    socket.emit("userEdit", values).once("userEditRes", (data) => {
      if (data.status) {
        alert("請等待顧問核實資料");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <MobileTitleHeader
        title={"個人資料"}
        returnButton
        tools={[{ icon: <AiOutlineCheck size={20} />, func: handleSubmit }]}
      />
      <div className="container my-3">
        <FormControl fullWidth>
          <TextField
            label="自我介紹"
            id="intro"
            name="intro"
            value={values.intro}
            onChange={handleChange}
            multiline
          />
          <TextField
            label={"姓名"}
            id={"name"}
            name={"name"}
            value={values.name}
            onChange={handleChange}
            className={"mt-3"}
          />

          <TextField
            label={"暱稱"}
            id={"nickname"}
            name={"nickname"}
            value={values.nickname}
            onChange={handleChange}
            className={"mt-3"}
          />

          <TextField
            id="birthday"
            type={"date"}
            name="birthday"
            value={values.birthday}
            onChange={handleChange}
            className="mt-3"
          />

          <FormLabel id="gender" className="mt-3">
            性別
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender"
            name="gender"
            onChange={handleChange}
            value={values.gender}
          >
            <FormControlLabel value="0" control={<Radio />} label="女性" />
            <FormControlLabel value="1" control={<Radio />} label="男性" />
          </RadioGroup>

          <Typography id="height" gutterBottom className="mt-3">
            身高（cm）
          </Typography>
          <div className="text-center">
            <Slider
              min={100}
              max={250}
              defaultValue={values.height}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "height", type: "number", value: value },
                });
              }}
              aria-labelledby="height"
              aria-label="Small"
              valueLabelDisplay="auto"
              style={{ width: "90%" }}
            />
          </div>
          <Typography id="weight" gutterBottom className="mt-3">
            體重（kg）
          </Typography>
          <div className="text-center">
            <Slider
              min={30}
              max={200}
              defaultValue={values.weight}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "weight", type: "number", value: value },
                });
              }}
              aria-labelledby="weight"
              aria-label="Small"
              valueLabelDisplay="auto"
              style={{ width: "90%" }}
            />
          </div>

          <TextField
            select
            value={values.blood}
            name="blood"
            label={"血型"}
            onChange={handleChange}
            className="mt-3"
          >
            {Object.keys(blood).map((e, i) => (
              <MenuItem value={e} key={i}>
                {blood[e].tc}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={"職業"}
            id={"occupation"}
            name="occupation"
            className="mt-3"
            value={values.occupation}
            onChange={handleChange}
          />

          <Typography id="salary" gutterBottom className="mt-3">
            年收入
          </Typography>
          <Slider
            min={1}
            max={5}
            defaultValue={values.salary}
            marks={[
              { value: 1, label: "<30萬" },
              { value: 2, label: ">30萬" },
              { value: 3, label: ">100萬" },
              { value: 4, label: ">500萬" },
              { value: 5, label: ">2000萬" },
            ]}
            getAriaValueText={(value) => value.label}
            onChange={handleChange}
            name="salary"
            aria-labelledby="salary"
            aria-label="Small"
            className="mx-3"
            style={{ width: "90%" }}
          />

          <Typography id="asset" gutterBottom className="mt-3">
            資產
          </Typography>
          <Slider
            min={1}
            max={5}
            defaultValue={values.asset}
            marks={[
              { value: 1, label: "<100萬" },
              { value: 2, label: ">100萬" },
              { value: 3, label: ">500萬" },
              { value: 4, label: ">2000萬" },
              { value: 5, label: ">1億" },
            ]}
            getAriaValueText={(value) => value.label}
            onChange={handleChange}
            name={"asset"}
            aria-labelledby="asset"
            aria-label="Small"
            className="mx-3"
            style={{ width: "90%" }}
          />

          <TextField
            select
            value={values.education}
            name="education"
            label={"學歷"}
            onChange={handleChange}
            className="mt-3"
          >
            {Object.keys(education).map((e, i) => (
              <MenuItem value={e} key={i}>
                {education[e].tc}
              </MenuItem>
            ))}
          </TextField>

          <Autocomplete
            multiple
            disableCloseOnSelect
            id="language"
            value={values.language}
            options={Object.keys(language)}
            getOptionLabel={(option) => language[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "language", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {language[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="語言" />}
            className="mt-3"
          />

          <Autocomplete
            multiple
            id="hobby"
            value={values.hobby}
            options={Object.keys(hobby)}
            disableCloseOnSelect
            getOptionLabel={(option) => hobby[option]["tc"]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "hobby", value: value },
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {hobby[option]["tc"]}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="興趣" />}
            className="mt-3"
          />

          <TextField
            select
            value={values.religion}
            name="religion"
            label={"宗教"}
            onChange={handleChange}
            className="mt-3"
          >
            {Object.keys(religion).map((e, i) => (
              <MenuItem value={e} key={i}>
                {religion[e].tc}
              </MenuItem>
            ))}
          </TextField>

          <Autocomplete
            multiple
            id="tag"
            freeSolo
            value={values.tag}
            options={[]}
            onChange={(e, value) => {
              handleChange({
                target: { name: "tag", value: value },
              });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, i) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ i })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="filled" label="標籤" />
            )}
            className="mt-3"
          />
        </FormControl>
      </div>
      {loading && <CircularLoading />}
    </>
  );
}
