import React, { useState } from "react";
import { PopUp } from "../popup/popup";
import { IoIosOptions } from "react-icons/io";
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
  Checkbox
} from "@mui/material";

import Slider from "@mui/material/Slider";
import {
  education,
  gender,
  hobby,
  language,
  religion,
  sign,
} from "../generalConst/generalConst";
import { BiArrowBack } from "react-icons/bi";

export default function Filter({ gen, onChange }) {
  const popupRef = React.useRef();
  const [values, setValues] = useState({
    age: [18, 60],
    height: [100, 250],
    weight: [30, 200],
    education: [],
    salary: "",
    asset: "",
    hobby: [],
    blood: [],
    location: [],
    sign: [],
  });
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [showPopup, setShowPopup] = useState(false);
  return (
    <>
      <IoIosOptions
        onClick={(e) => {
          setShowPopup(true);
        }}
      />
      {showPopup && (
        <PopUp ref={popupRef}>
          <header
            className="nav position-sticky container text-center py-3 bg-white"
            style={{ height: "60px" }}
          >
            <button
              className="img-btn d-flex align-items-center "
              style={{ fontSize: "12px" }}
              onClick={() => {
                popupRef.current.handleClosePopUp();
                onChange(values);
                setTimeout(() => {
                  setShowPopup(false);
                }, 200);
              }}
            >
                <BiArrowBack size={"20px"} /> 
              返回
            </button>
            <div className="absolute-middle">條件篩選</div>
          </header>
          <FormControl fullWidth>
            <div className="d-flex align-content-between">
             <div>性別:</div>
              <div>{gen? gender[0]["tc"]:gender[1]["tc"]}</div>
            </div>
            <Typography id="age" gutterBottom>
              年齡
            </Typography>
            <Slider
              min={18}
              max={80}
              defaultValue={[18, 20]}
              value={values.age}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "age", type: "number", value: value },
                });
              }}
              aria-labelledby="age"
              valueLabelDisplay="auto"
              className="mb-3"
            />
            <Typography id="height" gutterBottom>
              身高（cm）
            </Typography>
            <Slider
              min={100}
              max={250}
              value={values.height}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "height", value: value },
                });
              }}
              aria-labelledby="height"
              valueLabelDisplay="auto"
            />

            <Typography id="weight" gutterBottom>
              體重（kg）
            </Typography>
            <Slider
              min={30}
              max={200}
              value={values.weight}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "weight", value: value },
                });
              }}
              aria-labelledby="weight"
              valueLabelDisplay="auto"
            />

          

           
            <Typography id="salary" gutterBottom className="mt-3">
              年收入
            </Typography>
            <Slider
              min={1}
              max={5}
              defaultValue={1}
              marks={[
                { value: 1, label: "<30萬" },
                { value: 2, label: ">30萬" },
                { value: 3, label: ">100萬" },
                { value: 4, label: ">500萬" },
                { value: 5, label: ">2000萬" },
              ]}
              getAriaValueText={(value) => value.label}
              onChange={handleChange}
              name="salary"
              aria-labelledby="salary"
              className="mx-3"
              style={{ width: "90%" }}
            />

            <Typography id="asset" gutterBottom className="mt-3">
              資產
            </Typography>
            <Slider
              min={1}
              max={5}
              defaultValue={1}
              marks={[
                { value: 1, label: "<100萬" },
                { value: 2, label: ">100萬" },
                { value: 3, label: ">500萬" },
                { value: 4, label: ">2000萬" },
                { value: 5, label: ">1億" },
              ]}
              getAriaValueText={(value) => value.label}
              onChange={handleChange}
              name={"asset"}
              aria-labelledby="asset"
              className="mx-3"
              style={{ width: "90%" }}
            />

            <Autocomplete
            multiple
              id="education"
              disableCloseOnSelect
              options={Object.keys(education)}
              getOptionLabel={(option) => education[option]["tc"]}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "education", value: value },
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {education[option]["tc"]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="學歷" />}
              className="mt-3"
            />

            <Autocomplete
              multiple
              disableCloseOnSelect
              id="language"
              options={Object.keys(language)}
              getOptionLabel={(option) => language[option]["tc"]}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "language", value: value },
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {language[option]["tc"]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="語言" />}
              className="mt-3"
            />

            <Autocomplete
              multiple
              id="hobby"
              options={Object.keys(hobby)}
              disableCloseOnSelect
              getOptionLabel={(option) => hobby[option]["tc"]}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "hobby", value: value },
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {hobby[option]["tc"]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="興趣" />}
              className="mt-3"
            />

            <Autocomplete
              multiple
              id="religion"
              options={Object.keys(religion)}
              disableCloseOnSelect
              getOptionLabel={(option) => religion[option]["tc"]}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "religion", value: value },
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {religion[option]["tc"]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="宗教" />}
              className="mt-3"
            />

            <Autocomplete
              multiple
              id="sign"
              options={Object.keys(sign)}
              disableCloseOnSelect
              getOptionLabel={(option) => sign[option]["tc"]}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "sign", value: value },
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {sign[option]["tc"]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="星座" />}
              className="mt-3"
            />
          </FormControl>
        </PopUp>
      )}
    </>
  );
}
