import React, { Fragment, useEffect, useState } from "react";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { Icon } from "../../library/component/frame/icon";
import socket from "../../library/config/socket";
import { genderColor } from "../../library/themeSetting/setting";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";

export default function Member() {
  const [member, setMember] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    socket.emit("member").once("memberRes", (data) => {
      if (data.status) {
        console.log(data);
        setMember(data.message);
      }
    });
  }, []);
  return (
    <div>
      {member.map((e, i) => (
        <Fragment key={i}>
          <div
            className="mt-3"
            onClick={() => {
              navigate({
                pathname: "/userInfo",
                search: `?user_id=${e.userId}`,
              });
            }}
          >
            <Icon
              style={{ maxWidth: 50, aspectRatio: 1 / 1 }}
              img={`data:image/png;base64,${new Buffer.from(e.img).toString(
                "base64"
              )}`}
              className="me-2"
            />
            <span className="me-2">{e.name}</span>
            <span className="me-2">{e.age}</span>
            <span className="me-2">
              {e.gender ? (
                <BsGenderMale color={genderColor[e.gender]} />
              ) : (
                <BsGenderFemale color={genderColor[e.gender]} />
              )}
            </span>
          </div>
          <hr />
        </Fragment>
      ))}
    </div>
  );
}
